import { memo } from 'react';

import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import CircularProgress from '../Common/CircularProgress';

import ImageGoogle from '../../../../assets/image/Booked/google.png';
import ImageOffice from '../../../../assets/image/Booked/office.png';

import clockIcon from '../../../../assets/svg/clock.svg';
import mapPinIcon from '../../../../assets/svg/map-pin.svg';

import '../../../../sass/private/Booking/Booked.scss';


const ModuleBooked = (props) => {
  const { pathwayColor, productCategoryColor, productTypeColor, title, startDate, endDate, centreName, productCategory, productType, googleCalendar, officeCalendar, messageError } = props;
  return (
    <div className="booking-profile module-booked">
      {messageError ?
        <div className="booked">
          <div className="booking-title">
            <div className="error-default">{messageError}</div>
          </div>
        </div> :
        <div className="booked">
          <div className="booking-title" style={{ borderLeft: `10px solid ${pathwayColor}` }}>
            <p className="booking-text">
              <span className={`cate`} style={{backgroundColor: productCategoryColor}}>{productCategory}</span>
              <span className={`cate`} style={{backgroundColor: productTypeColor}}>{productType}</span>
            </p>
            <div className="booked-header__title">
              <h2>
                <span>{title}</span>
              </h2>
            </div>
            <p className="booking-subtitle">
              <img src={clockIcon} alt="Time" />
              <span>{startDate} - {endDate}</span>
            </p>
            <p className="booking-text">
              <img src={mapPinIcon} alt="Location" />
              <span>{centreName}</span>
            </p>
          </div>
          <div className="line-grey full-width"></div>
          
          <div className="booked-social">
            <h3 className='booked-social__title'>Your module booking is confirmed</h3>
            <p className="booked-social__text">
              Your lessons will now appear in your schedule.<br/>
              Select one of the options below to add them to your personal calendar too.
            </p>
            <div className="booked-social__row">
              <div className="social-box social-left">
                <img src={ImageGoogle} alt="Google Calendar" />
                <p className="social-box__text">Google Calendar <a target="_blank" rel='noreferrer' href={googleCalendar}>Add</a></p>
              </div>
              <div className="social-box social-right">
                <img src={ImageOffice} alt="Office Calendar" />
                <p className="social-box__text">Office Calendar <a target="_blank" rel='noreferrer' href={officeCalendar}>Add</a></p>
              </div>
            </div>
          </div>
          
        </div>
      }
    </div>
  )
}

export default memo(ModuleBooked);