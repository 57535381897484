import React, { useState, useEffect } from 'react';
import PublicPage from '../../components/PublicPage';
import  useQuery from '../../../utils/useQuery'
import authApi from '../../redux/services/auth.service';
import '../../../sass/public/ResetPassword.scss';
import CircularProgress from '../private/Common/CircularProgress';
import Logo from '../../../assets/image/british_council.svg';
import {getRoute} from '../../components/Utilities';

const ImpersonateStudent = (props) => {
  const { data } = useQuery()
  const [isLoading, setIsLoading] = useState(true);
  const [studentCode, setStudentCode] = useState('');
  const [textMessage, setTextMessage] = useState('');

  useEffect(() => {
    setIsLoading(true);
    setStudentCode('');
    setTextMessage('The request is being processed. Please wait a moment !');
    const stringData = atob(data);
    let user = {
      credentials: {},
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      group: '',
      fullname: '',
    };

    let object = JSON.parse(stringData);
    let currentUser = localStorage.getItem('user');
    let isImpersonated = false;
    let impersonateTarget = null;
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      isImpersonated = currentUser.isImpersonating || false;
      impersonateTarget = currentUser.impersonateTarget || null;
    }

    if (!isImpersonated || (isImpersonated && impersonateTarget === object.student_id)) {
      object.expires_in = 7200;
      object.expires_time = Date.now() + (object.expires_in * 1000);
      user.credentials = object;
      user.impersonateTarget = object.student_id;
      user.isImpersonating = true;
      localStorage.setItem("user", JSON.stringify(user));
      setStudentCode(object.student_code);

      if (object.url) {
        localStorage.setItem("apiEndpointPrefix", object.url);
      }

      authApi.getImpersonateToken(user.impersonateTarget).then((response) => {
        user.credentials = response.data;
        user.credentials.expires_time = Date.now() + (user.credentials.expires_in * 1000);
        localStorage.setItem("user", JSON.stringify(user));

        authApi.whoami().then((response) => {
          setIsLoading(false);
          user.first_name= response.data.surname_en;
          user.last_name= response.data.name_en;
          user.email= response.data.email;
          user.fullname= response.data.name_en + ' ' + response.data.surname_en;
          user.levelName= response.data.levelName;
          user.responseUserData= response.data;

          localStorage.setItem("user", JSON.stringify(user));
          window.location.href = process.env.PUBLIC_URL + getRoute("ROOT");
        });
      });
    } else {
      setStudentCode(object.student_code);
      setIsLoading(false);
      setTextMessage('You are already logged into another student account. Please log out first.');
    }
  }, []);

  return (
  <>
    {<PublicPage className="public-page">
      <div>
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue" />
        <h5 className="login-header__title">Impersonating Student {studentCode}</h5>
        <p className="login-header__text">{textMessage} {isLoading && <CircularProgress />}</p>
      </div>
    </PublicPage>}
  </>
  )
}

export default ImpersonateStudent;
