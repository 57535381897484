import React, { useState } from "react";
import Page from "./Page";
import Container from "./Container";
import { getRoute, getString } from "./Utilities";
import Footer from "../pages/private/Footer/Footer";
import Logo from "../../assets/image/british_council.svg";
import UserMenu from '../pages/private/HeaderMenu/UserMenu';
// import useHelpPage from "../hooks/HelpPage/useHelpPage";
import useCheckMobileTablet from '../../utils/useCheckMobileTablet';
import MainMenu from '../pages/private/HeaderMenu/MainMenu';
import { useHistory } from 'react-router';
import MainMenuMobile from '../pages/private/HeaderMenu/MainMenuMobile';
import iconArrowLeft from '../../assets/svg/arrowleft.svg';
import iconClose from '../../assets/svg/close.svg';

// export default class HelpPage extends React.Component {
const HelpPage = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     searchOpen: false,
  //   };
  // }
 
    const onClickLogo = () => {
      history.push(getRoute("HOME"));
    };
    // const {helpData} = props;
    const {
      isShowMainNavigation,
      history,
    } = props;
    const { isTablet } = useCheckMobileTablet();
    const showMenu = () => {
      return isTablet ? <></>: <MainMenu />;
    }

    

    return (
      <Page className={"help-centre-page" + getString(props.className)}>
         {!isTablet &&  <UserMenu />}
        <header className="header">
          {!isTablet && <div className="header__left">
            <img
              className="img-logo"
              src={Logo}
              alt="British Council"
              onClick={onClickLogo}
            />
            <h2 className="heading-navbar" onClick={onClickLogo}>MyClass</h2>
            <span className="current-page">Help centre</span>
          </div>}
          {isTablet && <div className="header__bottom">
            <h2 className="heading-navbar" onClick={onClickLogo}>
              MyClass
            </h2>
            <span className="current-page">Help centre</span>
          </div>}
        </header>
        {showMenu()}
        <main>
          <Container className="page-content">{props.children}</Container>
          {isTablet && isShowMainNavigation && <MainMenuMobile />}
        </main>
        <Footer />
      </Page>
    );
}
export default HelpPage