import { memo } from 'react';

import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../Common/AvailabilityDisplay';
import Star from '../../../components/icon/Star';
import Volume from '../../../components/icon/Volume';
import ArrowRight from '../../../components/icon/ArrowRight';
import Check from '../../../components/icon/Check';
import Key from '../../../components/icon/Key';
import CircularProgressBar from '../Common/CircularProgressBar';
import '../../../../sass/private/MyCourse/LessonItem.scss';
import ReactHtmlParser from 'react-html-parser';

const LessonItem = (props) => {
  const { 
    text, 
    info,
    attendedTime,
    checked, 
    disable, 
    isRating,
    isWishList,
    isLocked,
    isSpeaker, 
    isActivity,
    activitiesData,
    onClickLesson, 
    lessonId,
    lessonType,
    lessonIcon,
    places,
    center_teacher,
    booked,
    isTop,
    isBot,
    isLast
  } = props;

  let className = "LessonItem lesson-theme";

  if (booked) {
    className += "-booked";
  }

  return (
    <div
      className={className + (isTop ? " lesson-top" : "") + (isBot ? " lesson-bot" : "") + (isLast ? " last-item" : "")}
      data-booked={booked || false}
      tabIndex={0}
      onClick={() => onClickLesson(lessonId, lessonType)}
      onKeyPress={() => onClickLesson(lessonId, lessonType)}
    >
      <div className={`item-left${disable ? ' disable' : ''}`}>
        <div className={`item-checkbox${checked ? ' checked' : ''}${disable ? ' disable' : ''}`}>
          {checked > 0 && <Check />}
        </div>
        <div>
          <div className="item-text secondary">{ReactHtmlParser(text)}</div>
          <div className="item-info">
            <LocationTeacherDisplay {...center_teacher} time={info} attended_time={attendedTime} />
            <AvailabilityDisplay availabilityText={places} />
          </div>
        </div>
      </div>
      <div className={`item-right secondary${isSpeaker ? ' has-speaker' : ''}`}>
        {/* {isRating && <Star className="icon" />} */}
        {isWishList && !isLocked && <Star className="icon" />}
        {isSpeaker && !isLocked && <Volume className="icon" />}
       
        {!isLocked && checked > 0 && isActivity && activitiesData && 
          <CircularProgressBar
            strokeWidth="3"
            sqSize="52"
            percentage={100 - (activitiesData?.average_grade*10)}
            completeProgress={activitiesData?.completion_percent}
          />}
        {isLocked ? 
          <Key className="icon" /> :
          <ArrowRight className="icon arrow-right"/>
        }
      </div>
    </div>
  )
}

export default memo(LessonItem);
