import { useEffect, useState } from 'react';
import PrivatePage from '../../../components/PrivatePage';
import Navbar from '../Settings/Navbar';
import NavBarLeft from './NavBarLeft';
import ChooseLesson from './ChooseLesson';
import CourseItem from './CourseItem';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useMyCourse from '../../../hooks/MyCoursePage/useMyCourse';
import { Skeleton } from "@mui/material";
//import { getCourseContentAction } from '../../../redux/actions/MyCoursePage/CoursePageAction'
import CircularProgress from '../Common/CircularProgress';

// import { Skeleton } from "@mui/material";
import PracticeListLoading from '../Calendar/CalendarListView/PracticeListLoading';

import '../../../../sass/private/MyCourse/MyCoursePage.scss';
import useProgress from "../../../hooks/HomePage/useProgress";
import { useSocket } from "../../../hooks/HomePage/useSocket";

const MyCoursePage = (props) => {
  const { isOpenProgressDetail } = props;
  const history = useHistory()
  const dispatch = useDispatch();
  const [isLvChanged, setLvChanged] = useState(false)
  const [message, setMessage] = useState("");

  // const {
  //   getData,
  //   // reloadData,
  // } = getCourseContentAction();
  const {
    isLoadingContent,
    courseSideBar,
    courseLevel,
    courseContent,
    c1Id,
    c2Id,
    c3Id,
    c3Type,
    c2UniqueId,
    userId,
    centreId,
    courseLevelSelected,
    isLoadingMenu,
    onChangeOption,
    onChangeCourseLevel,
    onSideBarClick,
    getData
  } = useMyCourse()

  const onClickMenu = (id) => {
    setLvChanged(true)
    onChangeCourseLevel(id);
  }

  const onClickLesson = (lessonId, lessonType) => {
    history.push(`/mycourse/${lessonId}?lessonType=${lessonType}&centreId=${centreId}&c1Id=${c1Id}&c2Id=${c2Id}&c3Id=${c3Id}&c3Type=${c3Type}&levelId=${courseLevelSelected}${c2UniqueId ? "&c2UniqueId=" + c2UniqueId : ""}`)
  }

  useEffect(() => {
    const actualc3Id = c3Id || "";
    const actualc3Type = c3Type || "";

    if (actualc3Id !== "" & actualc3Type !== "") {
      getData(actualc3Type, actualc3Id, userId, centreId);
    }
  },[c3Id, c3Type, centreId, dispatch, userId])

  useEffect(() => {
    if(isLvChanged && courseSideBar) {
      onSideBarClick()
    }
  },[courseSideBar, isLvChanged, onSideBarClick])

  useEffect(() => {
    !c1Id && !c2Id && !c3Id && onSideBarClick()

  },[c1Id, c2Id, c3Id, c3Type, centreId, dispatch, onSideBarClick, userId])

  //update socket
  useEffect(() => {
    if (isOpenProgressDetail == false) {
      onSideBarClick();
    }
  }, [isOpenProgressDetail]);
  
  useSocket({
    callBack: (payload) => {
      onSideBarClick();
      setMessage(payload.message);
    }
  });
  
  
  const handleCloseDialog = () => {
    // reload data
    const actualc3Id = c3Id || "";
    const actualc3Type = c3Type || "";

    if (actualc3Id !== "" & actualc3Type !== "") {
      getData(actualc3Type, actualc3Id, userId, centreId);
    }
  };

  return (
    <PrivatePage className="MyCoursePage">
      <Navbar menuLists={courseLevel} onClickMenu={onClickMenu} />
      <div className="main-content">
        {isLoadingMenu && <CircularProgress />}
        {!isLoadingMenu && <>
        <div>
        <NavBarLeft 
            c1Id={c1Id} 
            c2Id={c2Id}
            c3Id={c3Id}
            c2UniqueId={c2UniqueId}
            courseSideBar={courseSideBar}
            onSideBarClick={onSideBarClick}
          />
        </div>
       
            <div className="main-right">
              <div className="main-header">
                <>
                <div className="sub-title">{courseContent?.heading}</div>
                <h1 className="title">{courseContent?.title}</h1>
                <div className="desc">{ReactHtmlParser(courseContent?.description)}</div>
                </>
              </div>
              <ChooseLesson 
                progressBar={courseContent?.list?.progress_bar} 
                listCentres={courseContent?.list?.centres} 
                centreId={centreId}
                listLessons={courseContent?.list?.lessons}
                listAssociated={courseContent?.list?.associated_lessons}
                onClickLesson={onClickLesson}
                onChangeOption={onChangeOption}
              />
               {isLoadingContent ? (
                  <div>
                    <div className='course-item__title course-title'>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        className="skeleton-heading"
                      />
                      </div>
                      <PracticeListLoading></PracticeListLoading>
                  </div>
                ) : (
              <CourseItem
                handleCloseDialog={handleCloseDialog}
                themeProgress={courseContent?.additional_content?.theme_progress}
                blocks={courseContent?.additional_content?.blocks}
                disable={courseContent?.additional_content?.theme_progress?.is_locked}
                themeProgresslist={courseContent?.heading}
                themeProgressTitle={courseContent?.title}
                themeProgressDesc={courseContent?.additional_content?.theme_progress?.hint}
                color={courseContent?.additional_content?.theme_progress?.color_hex}
              />
              )}
              <div className="assessment-pdf">
              </div>
            </div>
        </>
        }
      </div>
    </PrivatePage>
  )
}

export default MyCoursePage;