import { ActionTypes } from '../types';

import MyclassFriend from "../../services/myClassFriendApi";

export const getMyclassFriendAction = () => (dispatch) => {
    return MyclassFriend.getMyclassFriend().then(
        (response) => {
          if (response && response.success) {
            dispatch({
              type: ActionTypes.GET_MYCLASS_FRIEND_SUCCESS,
              payload: response.data
            })
          } else {
            dispatch({
              type: ActionTypes.GET_MYCLASS_FRIEND_FAIL,
              payload: 'Get MyClass friend failed!'
            })
          }
        }
    );
};

export const sendInvitation = (data) => (dispatch) => {
  return MyclassFriend.sendEmailInvitation(data).then(
    (response) => {
      if (response && response.success) {
        dispatch({
          type: ActionTypes.ADD_INVITE_EMAIL_SUCCESS,
          payload: response.data
        })
      } else {
        dispatch({
          type: ActionTypes.ADD_INVITE_EMAIL_FAIL,
          payload: 'Add invite failed!'
        })
      }
      return response
    }
  )
}

export const handleInvitation = (friend, status) => (dispatch) => {
  return MyclassFriend.handleActionInvitation(friend, status).then(
    (response) => {
      if (response && response.success) {
        dispatch({
          type: ActionTypes.HANDLE_INVITE_SUCCESS,
          payload: {
            friend,
            status,
          }
        })
      } else {
        dispatch({
          type: ActionTypes.HANDLE_INVITE_FAIL,
          payload: 'Handle invite failed!'
        })
      }
      return response
    }
  )
}