import React, { Fragment } from 'react';
import PublicPage from '../../components/PublicPage';
import { Button } from '@material-ui/core';
import AccoutActiveFooter from './AccoutActiveFooter';
import Logo from '../../../assets/image/british_council.svg';
import IconCheck from '../../../assets/svg/iconcheck.svg'
import IconAppStore from '../../../assets/svg/appstore.svg'
import IconGGPlay from '../../../assets/svg/ggplay.svg'
import IconZoom from '../../../assets/svg/zoom.svg'
import useCheckMobileTablet from '../../../utils/useCheckMobileTablet';
import { useLocation } from 'react-router-dom';

import '../../../sass/public/AccountActived.scss'


const AccountActivation = () => {

  const { isTabletCustom } = useCheckMobileTablet();
  const location = useLocation();

  return (
    <PublicPage className="page-activated public-page">
      <div className="activated-header">
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue"></div>
        <div className="activated-title">
          <div className="activated-title__icon-check">
            <img src={IconCheck} alt="iconCheck"/>
          </div>
          <div className="activated-title__title">Your account has been activated</div>
        </div>
        
        <p className="activated-header__text">
          You are ready to log in and start booking classes at &nbsp; 
          <a rel="noopener noreferrer" 
              href="https://myclass.britishcouncil.org" 
              target="_blank"
              className="color-link">https://myclass.britishcouncil.org
          </a>
        </p>
        
        {
          !isTabletCustom && <AccoutActiveFooter phone = { location.state.phone } />
        }
        
      </div>


     
      <div className="form-activated-block">
        <div className="content">
          <div className="des">You can also choose to book your classes using the MyClass booking apps for iPhone/iPad and Android</div>
          <div className="app-extend">
            <div className="icon-app">
              <img src={IconAppStore} alt="App Store"/>
            </div>
            <a href="https://myclass.britishcouncil.org/upload/app_redirect.htm" target="_blank" className="app-title">Download on the App Store</a>
          </div>

          <div className="app-extend">
            <div className="icon-app">
              <img src={IconGGPlay} alt="Google Play"/>
            </div>
            <a href="https://myclass.britishcouncil.org/upload/app_redirect.htm" target="_blank" className="app-title">Get it on Google Play</a>
          </div>

          <div className="divider"></div>

          <div className="des">MyClass Online is delivered using Zoom. <br />For the best online classroom experience, please download and install Zoom on your desktop or laptop</div>
          
          <div className="app-extend">
            <div className="icon-app">
              <img src={IconZoom} alt="Zoom"/>
            </div>
            <a href="https://zoom.us/download" target="_blank" className="app-title">Download Zoom</a>
          </div>
        </div>
      </div>
      {
        isTabletCustom &&  <AccoutActiveFooter phone = { location.state.phone } />
      }
         
    </PublicPage>
  )
}

export default AccountActivation;