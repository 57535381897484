import { useEffect, useState } from 'react';
import useFeedback from '../../../hooks/Feedback/useFeedback';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { setFeedbackScreenOne } from '../../../redux/actions/Feedback/FeedbackAction';
import IconClock from '../../../../assets/svg/clock.svg';
import IconUser from '../../../../assets/svg/user.svg';
import '../../../../sass/private/FbQuestion/FbQuestion.scss';

const FbQuestion1 = (props) => {
  const dispatch = useDispatch();
  const {lessonTimeId, isRated, setIsRated} = props;
  const onClose = () => {
    props.onClose();
  }
  const onNext = () => {
    props.onNext();
  }
  const {feedbackDataScreenOne, openFeedbackScreenOne} = useFeedback();
  useEffect(async() => {
    if (lessonTimeId) {
      await openFeedbackScreenOne(lessonTimeId);
    }
  }, [lessonTimeId]);
  const [value, setValue] = useState(0);
  const [hover, setHover] = useState(-1);

  const onRating = (lessonTimeId, value) => {
    if (!value) setIsRated(false);
    else setIsRated(true);
    dispatch(setFeedbackScreenOne({
      lesson_time_id: lessonTimeId,
      star: value
    }));
  };

  return (
    <div className="feedback-wrapper">
      <div className="feedback">
        <header>
          <div className="line"></div>
          <h1>Help us improve MyClass</h1>
        </header>
        <main className="main">
          <div className="main-content">
            <div className="main-desr">
              <h2 className="ttl-2">
                {feedbackDataScreenOne?.question_text}
              </h2>
              <div className="line"></div>
              <div className="lesson-type">
                {feedbackDataScreenOne?.lesson_type}
              </div>
              <div className="lesson-txt">
                {feedbackDataScreenOne?.lesson_text}
              </div>
              <div className="date txt">
                <img src={IconClock} alt="icon clock"/>
                {feedbackDataScreenOne?.date}
              </div>
              <div className="txt-teacher txt">
                <img src={IconUser} alt="icon user"/>
                {feedbackDataScreenOne?.teacher_name}
              </div>
              <Box
                sx={{
                  width: 200,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    onRating(lessonTimeId, newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={<StarIcon className="staricon" style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
              </Box>
              {
                isRated ? 
                <button className="btn-next" onClick={onNext}>Next</button> :
                <button className="btn-close" onClick={onClose}>Close</button>
              }
            </div>
          </div> 
        </main>
      </div>
    </div>
  )
}

export default FbQuestion1;