import '../../../../sass/private/Home/ItemLessonMobile.scss'
import { Button } from '@material-ui/core';
import { windowOpen } from '../../../components/Utilities';

import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../Common/AvailabilityDisplay';

import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Check from '../../../components/icon/Check';
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';

export const ItemLessonMobile = (props) => {
  const {
    nameLesson, 
    time,
    teacher,
    borderColor,
    onClickLesson,
    lessonTimeId,
    showJoinNow,
    joinLink,
    joinNowText,
    showIconUsers,
    showIconStar,
    showIconLevel,
    showIconWorkplace,
    showIconTick,
    availabilityText,
    showAlertIcon,
    module,
    theme
  } = props
  return (
    <div 
      className="item-lesson lesson-theme-booked"
      style={{borderLeft: `6px solid ${theme.pathway_color ? theme.pathway_color : 'gray'}`}}
      onClick={() => onClickLesson(lessonTimeId)}
    >
      <div className="name-lesson secondary">{ReactHtmlParser(nameLesson)}</div>
      <div>
        <LocationTeacherDisplay time={time} {...teacher} teacher="" />
        <AvailabilityDisplay availabilityText={availabilityText} showAlertIcon={showAlertIcon} />
      </div>
      {showJoinNow && 
        <Button 
          variant="contained" 
          className="btn btn-join-mobile secondary"
          onClick={(e) => windowOpen(joinLink, e)}
        >
          {joinNowText}
        </Button>
      }
      <div className="icon-block secondary">
        {showIconUsers && <Users />}
        {showIconStar && <Star />}
        {showIconLevel && <Level code={showIconLevel} />}
        {showIconWorkplace && <Workplace />}
        {showIconTick && <Check />}
        {module && <Layers />}
      </div>
    </div>
  )
}

export default ItemLessonMobile