import React, { useMemo, Fragment } from 'react';
import { Button, FormGroup, TextField } from '@material-ui/core';
import useMyFriend from '../../../hooks/SettingPage/useMyFriend';

const MyClassFriend = () => {
  const {
    email,
    listPendding,
    listFriend,
    listRequest,
    errMsg,
    handleChange,
    onSendInvitation,
    onHandleInvitation,
  } = useMyFriend();

  const content = () => {
    return (
      <div className="settingItem">
        <form>
          <FormGroup noValidate autoComplete="off" className="form-login">
            <div className="form-title">Enter student’s email address</div>
            <TextField
              id={'login-email outlined-name'}
              name={'email'}
              value={email}
              onChange={handleChange}
              className="form-login__input"
              variant="outlined"
            />

          </FormGroup>
          {errMsg.length > 0 && <div className="error-default">{errMsg}</div>}
          <Button variant="contained" className="btn btn-return btn-bottom" onClick={onSendInvitation}>Send invitation</Button>
        </form>
      </div>
    )
  };
  return (
    <Fragment>
      <div className="setting-title">
        MyClass friends
      </div>
      <div className="setting-subtitle setting-subtitle__myfriend">
        MyClass friends offers you a way to share your booking with other MyClass students.
        If your friend request is accepted, you and your friend will both be able to see which classes you have booked.
      </div>
      <div className="communication MyClassFriend">
        {content()}
        <h3 className="MyClassFriend-title">MyClass friends</h3>
        {listFriend?.length > 0 ?
          <div className="friend-list">
            {listFriend?.map(item => {
              return (
                <div className="friend-item" key={item.id}>
                  <div className="request-item__top">
                    <div className="friend-item__img">
                      <img src={item.avatar} alt="Friend picture" />
                    </div>
                    <div className="friend-item__name">{item.name} {item.sur_name}</div>
                  </div>

                  <div className="request-item__action">
                    <Button
                      className="btn MuiButton-contained btn-default btn-unfriend"
                      onClick={() => onHandleInvitation(item, "unfriend")}
                    >
                      Remove
                    </Button>
                  </div>
                </div>

              )
            })}
          </div> :
          <div className="no-friend">You don’t currently have any MyClass friends.</div>
        }

        <h3 className="MyClassFriend-title">Pending invitations</h3>
        {/* <Button className="btn MuiButton-contained btn-default" onClick={() => onHandleInvitation({id: 1, name: '123'}, "accept")}>Accept</Button> */}
        {listPendding?.length > 0 ?
          <div className="friend-request">
            {listPendding?.map(item => {
              return (
                <div className="request-item" key={item.id}>
                  <div className="request-item__top">
                    <div className="--avatar">
                      <img src={item.avatar} alt={item.name} />
                    </div>

                    <div className="--name">{item.name} {item.sur_name}</div>
                  </div>


                  <div className="request-item__action">
                    <Button
                      className="btn MuiButton-contained btn-default btn-accept"
                      onClick={() => onHandleInvitation(item, "accept")}
                    >
                      Accept
                    </Button>
                    <Button
                      className="btn MuiButton-contained btn-default btn-ignore"
                      onClick={() => onHandleInvitation(item, "ignore")}
                    >
                      Ignore
                    </Button>
                  </div>
                </div>
              )
            })}
          </div> :
          <div className="no-friend">You don’t currently have any MyClass friends.</div>
        }

        <h3 className="MyClassFriend-title">Request invitations</h3>
        {
          listRequest?.length > 0 ?
            <div className="friend-request">
              {
                listRequest?.map(item => {
                  return (
                    <div className="request-item" key={item.id}>
                      <div className="request-item__top">
                        <div className="--avatar">
                          <img src={item.avatar} alt={item.name} />
                        </div>

                        <div className="--name">{item.name} {item.sur_name}</div>
                      </div>

                      <div className="request-item__action">
                        <Button
                          className="btn btn-cancel"
                          onClick={() => onHandleInvitation(item, "cancel")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )
                })
              }

            </div>
            : <div className="no-friend">You don’t currently have any MyClass friends.</div>

        }
      </div>
    </Fragment>
  )
}

export default MyClassFriend;