import React, { useState, useEffect } from 'react';
import PublicPage from '../../components/PublicPage';
import { Button, FormGroup, FormLabel, TextField } from '@material-ui/core';
import { getRoute } from '../../components/Utilities';
import { validationSchema } from './validationSchema';
import { useFormik } from 'formik';
import AccoutActiveFooter from './AccoutActiveFooter';
import Logo from '../../../assets/image/british_council.svg';
import useCheckMobileTablet from '../../../utils/useCheckMobileTablet';
import { useParams } from 'react-router-dom';
import  useQuery from '../../../utils/useQuery'
import resetPasswordApi from '../../redux/services/resetPasswordApi';
import '../../../sass/public/ResetPassword.scss';
import NotFound from '../public/NotFound';
import CircularProgress from '../private/Common/CircularProgress';

const ResetPassword = (props) => {
  const { token} = useParams()
  const { email } = useQuery()
  const { history } = props;
  const [errMsg, setErrMsg] = useState('');
  const [accountInfo, setAccountInfo] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    resetPasswordApi.validateResetPassword(email, token).then((response) => {
      setIsLoading(false);
      setAccountInfo(response.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      accountPassword: '',
      accountRePassword: '',
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      setDisableSubmit(true);
      setErrMsg('');
      const formData = {
        email: email,
        password: values.accountPassword,
        password_confirmation: values.accountRePassword,
      }

      return resetPasswordApi.resetPassword(token, formData).then(response => {
        if(response && response.success) {
          history.push(getRoute("RESETPASSWORDACTIVED"));
        }
        else {
          setDisableSubmit(false);
          setErrMsg(response.message)
        }
      })
      
    },
  });

  const { isTabletCustom } = useCheckMobileTablet();

  return (
  <>
    {isLoading && <CircularProgress />}
    {!isLoading && !accountInfo.is_valid && <NotFound/>}
    {!isLoading && accountInfo.is_valid && <PublicPage className="reset-pw public-page">
      <div className="resetpw-header">
        <div className="my-class">
          <img src={Logo} alt="logo"/>
        </div>
        <div className="line-blue"></div>
        <h5 className="resetpw-header__title">Welcome to MyClass</h5>
        <p className="resetpw-header__text">Please enter a new password.</p>
      
        {!isTabletCustom && !isLoading && <AccoutActiveFooter phone={accountInfo.phone}/>}
      </div>
      
      <div className="form-reset-block">
        <form onSubmit={formik.handleSubmit} className="form" autoComplete="off">
          <FormGroup noValidate autoComplete="off" className="form-reset">

            <div className="description-password">
              <div className="des-title1">Reset your password</div>
              <div className="des-title2">Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number</div>
            </div>

            <FormLabel htmlFor="account-password" className="form-reset__label">New password</FormLabel>
            <TextField
              id={'account-password'}
              name={'accountPassword'}
              type="password"
              required
              className="form-reset__input"
              variant="outlined"
              value={formik.values.accountPassword}
              onChange={formik.handleChange}
              error={formik.touched.accountPassword && Boolean(formik.errors.accountPassword)}
            />
            {(formik.touched.accountPassword && formik.errors.accountPassword) &&
              <div className="error-block">
                <div className="error-border"></div>
                <div className="error-password">{formik.errors.accountPassword}</div>
              </div>
            }
            <FormLabel htmlFor="account-repassword" className="form-reset__label">Re-enter your new password</FormLabel>
            <TextField
              type="password"
              id={'account-repassword'}
              name={'accountRePassword'}
              required
              className="form-reset__input"
              variant="outlined"
              value={formik.values.accountRePassword}
              onChange={formik.handleChange}
              error={formik.touched.accountRePassword && Boolean(formik.errors.accountRePassword)}
            />
            {(formik.touched.accountRePassword && formik.errors.accountRePassword) &&
              <div className="error-block">
                <div className="error-border">{errMsg}</div>
                <div className="error-password">{formik.errors.accountRePassword}</div>
              </div>
            }
            
            <div className="error-block">
              {errMsg && <div className="error-password">{errMsg}</div> }
              </div>
            <div>
              <Button variant="contained" type="submit" className="btn btn-indigo" disabled={disableSubmit}>Activate</Button>
            </div>
          </FormGroup>
        </form>
      </div>
      {
        isTabletCustom &&  <AccoutActiveFooter phone={accountInfo.phone} />
      }
    </PublicPage>}
  </>
  )
}

export default ResetPassword;