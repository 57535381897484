import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class HomePageApi {
  async getLanding(nextClassOnly = false) {
    try {
      const url = `landing?nextClassOnly=${nextClassOnly? 1: 0}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getNewsFeed(id) {
    try {
      let url = `news-feed-list?student_id=${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getNewsFeedLogin() {
    try {
      let url = `news-feed-at-login`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async showNewsFeed(data) {
    try {
      let url = `dismiss-news-feed`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        data,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getProgressCheck() {
    try {
      let url = `progress-check`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getMoreProgressCheck(type, page) {
    try {
      let url = `progress-check?type=${type}&page=${page}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getModule(isBooked = 0, centre = 0) {
    try {
      let url = `modules?limit=3&is_booked=${isBooked}&id_centre=${centre}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getMoreModule(type, page, centre = 0) {
    var isBooked = type === 'booked' ? 1 : 0
    try {
      let url = `modules?is_booked=${isBooked}&page=${page}&limit=3&id_centre=${centre}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getModuleDetail(id) {
    try {
      let url = `modules/${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getValidateModule(id, optional=[]) {
    const data = {
      "optional_lesson_time_ids": optional
    }
    try {
      let url = `modules/validate-booking/${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        data,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getBookingModule(id, optional=[]) {
    const data = {
      "optional_lesson_time_ids": optional,
      "booking_type": 1,
      "notify_student": 1
    }
    try {
      let url = `modules/book/${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        data,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getValidateCancelModule(id) {
    try {
      let url = `modules/validate-canceling/${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getCancelModule(id) {
    try {
      let url = `modules/cancel/${id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        {"notify_student": 1},
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

}

export default new HomePageApi();