import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { getRoute, getTarget } from '../../../components/Utilities';
import Logo from '../../../../assets/image/british_council.svg';
import searchIconWhite from '../../../../assets/image/search_white.png';
import iconBell from '../../../../assets/svg/bell.svg'
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import useQuery from '../../../../utils/useQuery';
import Modal from '../Dialog/Modal';
import ReactHtmlParser from 'react-html-parser';
import iconArrowLeft from '../../../../assets/svg/arrowleft.svg'
import CircularProgress from '../Common/CircularProgress';
import { Button } from '@material-ui/core';

import '../../../../sass/private/MainMenu.scss';
import useNotification from '../../../hooks/NotificationPage/useNotificationPage';
import moment from "moment";
import NotificationItem from './NotificationItem';
import { useDispatch } from 'react-redux';
import { resetNotificationState } from '../../../redux/actions/Notification/NotificationAction';

const menuListInitial = [
  {
    name: 'Home',
    link: 'HOME',
    active: true,
    className: ''
  },
  {
    name: 'My course',
    link: 'MYCOURSE',
    active: false,
    className: ''
  },
  {
    name: 'Progress',
    link: 'PROGRESS',
    active: false,
    className: ''
  },
  {
    name: 'Assessment',
    link: 'ASSESSMENT',
    active: false,
    className: ''
  },
  {
    name: 'Teachers',
    link: 'TEACHERS',
    active: false,
    className: "menu-item__last"
  }
]

const menuNoAssessment = [
  {
    name: 'Home',
    link: 'HOME',
    active: true,
    className: ''
  },
  {
    name: 'My course',
    link: 'MYCOURSE',
    active: false,
    className: ''
  },
  {
    name: 'Progress',
    link: 'PROGRESS',
    active: false,
    className: ''
  },
  {
    name: 'Teachers',
    link: 'TEACHERS',
    active: false,
    className: "menu-item__last"
  }
]

const MainMenu = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const userData = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const isShowAssessment = userData.responseUserData?.is_show_assessment || false;

  const [menuList, setMenuList] = useState(isShowAssessment ? menuListInitial : menuNoAssessment);
  const {notificationData, openNotification} = useNotification()
  const { isTablet } = useCheckMobileTablet();
  const queryParam = useQuery();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const onRedirect = link => {
    if (!link) return;
    history.push(getRoute(link));
    onChangeActiveLink(link)
  }

  const onChangeActiveLink = (link) => {
    const newListMenu = [...menuList];
    newListMenu.filter(item => {
      if (item && item.link === link) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    })
    setMenuList(newListMenu)
  }

  useEffect(() => {
    if (isTablet && queryParam.target === getTarget("NOTIFICATIONLIST")) {
      openNotificationList();      
      if(typeof queryParam.open == "undefined") {
        history.replace(history.location.pathname);        
      }
    }
  }, []);

  useEffect(() => {
    const link = history.location.pathname;
    if (link) {
      onChangeActiveLink(link.split('/')[1].toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  const content = menuList.map((item, index) => {
    return <li
      key={index}
      tabIndex={0}
      className={`${item.className} ${item.active ? 'active' : ''}`}
      onClick={() => onRedirect(item.link)}
      onKeyPress={() => onRedirect(item.link)}
    >{item.name}</li>
  })

  const onClickLogo = () => {
    history.push(getRoute("HOME"));
  }

  const onClickNotification = (currentPage, perPage) => {
    setOpenModal(true)
    setIsLoading(true)
    openNotification(currentPage, perPage)
    setIsLoading(false)
  }

  const handleCloseDialog = () => {
    setOpenModal(false)
  }

  const openNotificationList = () => {
    dispatch(resetNotificationState(false));
    onClickNotification(1, 10);
  }

  return (
    <div className="main-menu">
      <div className="menu-left">
        <div className="my-class">
          <img className="img-logo" src={Logo} alt="British Council" onClick={onClickLogo} />
          <div className="title">MyClass</div>
        </div>
        {isTablet && 
        <img 
          className="notification-icon" 
          src={iconBell} alt="Notifications" 
          onClick={openNotificationList}
        />}
      </div>

      {
        !isTablet && 
        <ul className="menu-right">
          {content}
        </ul>
      }

      <Modal useThemeClasses={true} open={openModal} handleCloseDialog={handleCloseDialog} className="notification-modal">
        <div className="profile-wraper__mobile-header">
          <img 
            onClick={handleCloseDialog}
            src={iconArrowLeft} 
            alt="iconleft" 
            className="iconBack"/>
          <div className="profile">Notifications</div>
        </div> 
        <NotificationItem notificationData={notificationData} onClickNotification={onClickNotification}/>
        {notificationData.is_more &&
          <div className="btn-show-more-container" style={{"paddingBottom": "100px", "justifyContent": "center", "display": "flex", "marginTop": "15px"}}>
            <Button 
              variant="contained" 
              className="btn btn-show-more" 
              onClick={() => 
                onClickNotification(
                  parseInt(notificationData.current_page) + 1,
                  notificationData.current_limit)} 
              disabled={isLoading
            }>
              {isLoading && <CircularProgress />}
              {!isLoading && "Load More"}
            </Button>
          </div>
        }
      </Modal>
    </div>
  )
}

export default withRouter(MainMenu);