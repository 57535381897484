import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, FormLabel, TextField } from '@material-ui/core';
import PublicPage from '../../components/PublicPage';
import { getRoute, isMaintenanceMode } from '../../components/Utilities';
import { login } from '../../redux/actions/auth';
import '../../../sass/public/Public.scss';
import rectangleIcon from '../../../assets/image/rectangle.png';
import alertIcon from '../../../assets/image/alert.png';
import Logo from '../../../assets/image/british_council.svg';

class Login extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
      },
      loginError: false,
      loginInProgress: false,
      isRemember: false,
      shouldRender: false,
      enableShowPassword: false
    };
    this.handleChange = (event) => {
      const {value, name} = event.target;
      const {user} = this.state;
      user[name] = value;
      this.setState({user});
      if(name=='password'){
        if(value !=""){
         this.setState({enableShowPassword : true});
        }else{
          this.setState({enableShowPassword : false});
        }
      }
    };
  }

  componentDidMount() {
    isMaintenanceMode()
      .then(response => {
        if (response) {
          window.location.href = process.env.PUBLIC_URL + getRoute("MAINTENANCE")
        } else {
          this.setState({ shouldRender: true })
        }
      })
    this._isMounted = true;
    window.scrollTo(0, 0);
  }

  onClickLogin = async (e) => {
    e.preventDefault();
    const {dispatch, history} = this.props;

    if ('' !== this.state.user.email && '' !== this.state.user.password) {
      this.setState({
        loginError: false,
        loginInProgress: true,
      });
      dispatch(login(this.state.user.email, this.state.user.password, this.state.isRemember))
        .then(() => {
          if (this._isMounted) {
            this.setState({loginError: false, loginInProgress: false})
            history.push(getRoute("DASHBOARD"));
          }
        })
        .catch(() => {
          if (this._isMounted) {
            this.setState({loginError: true, loginInProgress: false})
            history.push(getRoute("ROOT"));
          }
        });
    }
  };

  onChangeRemember = () => {
    this.setState((prevState) => {
      return {
        isRemember: !prevState.isRemember
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  state = {
    isPasswordShown: false
  };
  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  render() {
    const { isRemember, loginError, loginInProgress, shouldRender, enableShowPassword} = this.state;
    const { history } = this.props;
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
    const { isPasswordShown } = this.state;
    return (
      shouldRender && <PublicPage className="page-login public-page">
        <div className="login-header">
          <div className="my-class">
            <img src={Logo} alt="British Council" />
          </div>
          <div className="line-blue"></div>
          <h5 className="login-header__title">Welcome to MyClass</h5>
          <p className="login-header__text">Take control of your learning with flexible options you can personalise to suit your needs</p>
        </div>
        <div className="form-login-block">
          <form onSubmit={this.onClickLogin} className="form">
            <div className="form-title">Log in</div>
            {loginError ? <div className="login-error">
            <div className="login-error__main">
              <img src={alertIcon} alt="icon" />
              <div>
                <div className="login-error__info">Incorrect email or password</div>
                <div className="login-error__desr">Try again or click Forgot password to reset it</div>
              </div>
            </div>
          </div> : <div className="line"></div>
          }
            <FormGroup noValidate autoComplete="off" className="form-login">
              <FormLabel htmlFor="login-email" className="form-login__label">Your email</FormLabel>
              <TextField
                id={'login-email outlined-name'}
                name={'email'}
                label=""
                onChange={this.handleChange}
                className="form-login__input"
                variant="outlined"
                inputProps={{ style: inputStyle }}
              />
              <FormLabel htmlFor="login-password" className="form-login__label label2">Password</FormLabel>
              <div className={`inner-pw ${enableShowPassword ? "icon-pw" : ""} `} >
                <TextField
                  type={isPasswordShown ? "text" : "password"}
                  id={'login-password outlined-name'}
                  name={'password'}
                  label=""
                  onChange={this.handleChange}
                  className="form-login__input"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                />
                <div
                      className={isPasswordShown ? "password-show password-icon" : "password password-icon"}
                      onClick={this.togglePasswordVisiblity}
                    >icon</div>
                </div>
              <div>
                <Button variant="contained" type="submit" className="btn btn-indigo" disabled={loginInProgress}>Log in</Button>
              </div>
            </FormGroup>
            <div className="login-footer">
              <div className="login-footer__forgot-password" onClick={() => {history.push(getRoute("FORGOTPASSWORD"));}}><span>Forgot password?</span></div>
              {
              !window.isNativeApp && <div className="remember-block">
                <div className="remember-icon" onClick={this.onChangeRemember}>
                  {isRemember ?
                    <img src={rectangleIcon} alt="remember" className="center"/> :
                    <div className="remember-icon__radio center"></div>
                  }
                </div>
                <div className="remember-text">Remember me</div>
              </div>
              }
            </div>
          </form>
          {/* <div className="line"></div> */}
        </div>
      </PublicPage>
    );
  }
}

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  const {message} = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(Login);