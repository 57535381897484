import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../Common/AvailabilityDisplay';

import Monitor from '../../../components/icon/Monitor';
import Mic from '../../../components/icon/Mic';
import Edit from '../../../components/icon/Edit';
import BookOpen from '../../../components/icon/BookOpen';
import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Check from '../../../components/icon/Check';

import { getCurrentUser } from '../../../../utils/currentUser';

import '../../../../sass/private/MyCourse/BookClass.scss';

const BookClassItem = (props) => {
  const { classItem, openBookingWindow } = props;
  const history = useHistory();
  const [idUser, setIdUser] = useState(0)

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.responseUserData) {
      const idUser = currentUser.responseUserData.id
      setIdUser(idUser)
    }

  },[])

  const onOpenBookingWindow = (userId, lessonTimeId) => {
    let pathname = history.location.pathname;
    let pathnameSplit = pathname.split('/')?.[1];
    history.push(`/${pathnameSplit}/${lessonTimeId}`);
  }

  const showIcon = () => {
    if(classItem?.locationIcon === "online") {
      return <Monitor />;
    } else {
      if(classItem?.productTypeIcon === "speaking") {
        return <Mic />;
      } else if (classItem?.productTypeIcon === "writing") {
        return <Edit />;
      } else {
        return <BookOpen />;
      }
    }
  }

  let className = "book-item lesson-theme";

  if (classItem?.booked) {
    className += "-booked";
  }

  const availabilityText = classItem?.infoLines?.availability;
  const showAlertIcon = classItem?.infoLines?.fullWarningIcon;

  return (
    <div key={classItem?.lessonTimeId} className={className} style={{ borderLeft: `6px solid ${classItem?.colorHex}`, }}>
      <div>
        <div className="book-item__title">{classItem?.sessionName}</div>
        <div className="book-item__title secondary">{classItem?.date}</div>
        <div className="book-item__desc">
          <span className="icon-block secondary">{showIcon()}</span>
          
          <span>
            <LocationTeacherDisplay {...classItem?.infoLines?.centreTeacherName} />
            <AvailabilityDisplay availabilityText={availabilityText} showAlertIcon={showAlertIcon} />
          </span>
          
        </div>
      </div>
      <div className="book-item__right">
        <div className="icon-block secondary">
          {classItem?.iconSet?.friend?.showIcon && <Users />}
          {classItem?.iconSet?.wishList?.showIcon && <Star />}
          {classItem?.otherLevel && <Level code={classItem.otherLevel} />}
          {classItem?.iconSet?.workPlace?.showIcon && <Workplace />}
          {classItem?.ticked && <Check />}
        </div>
        <Button
          variant="contained"
          className={`btn btn-default ${classItem?.actionArea?.showButtonBook ? '' : 'btn-detail'}`}
          onClick={() => openBookingWindow(classItem?.lessonTimeId)}
        >{classItem?.actionArea?.showButtonBook ? 'Book' : 'Details'}</Button>
      </div>

    </div>
  )
}

export default BookClassItem;