import { memo, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import "../../../../sass/private/Module/ModuleListViewMobile.scss";

import ModuleListLoading from "./ModuleListLoading";
import useModule from "../../../hooks/HomePage/useModule";
import ModuleBookingMobile from "./ModuleBookingMobile";
import ArrowDown from "../../../components/icon/ArrowDown";
import ModuleViewItemMobile from "./ModuleViewItemMobile";

const ModuleListViewMobile = (props) => {
  

  const {
    isLoadingAvailable,
    isLoadingBooked,
    isLoadingMoreBooked,
    isLoadingMoreAvailable,
    moduleListAvailable,
    moduleListBooked,
    showLoadMoreButtonBooked,
    showLoadMoreButtonAvailable,
    totalBooked,
    totalAvailable,
    loadMore,
    BOOKED,
    AVAILABLE,
    getData,
    dataReloadTokenSelf,
    reloadModuleData,

    isLoadCentre,
    centre,
    onChangeOption,
    dataOptionAddress
  } = useModule();
  const [isOpenModuleDetail, setIsOpenModuleDetail] = useState(false);
  const [isOpenDesc, setIsOpenDesc] = useState(false);
  const [moduleID, setModuleID] = useState();
  
  useEffect(() => {
    if (isOpenModuleDetail === false && dataReloadTokenSelf) {
      getData();
    }
  }, [isOpenModuleDetail, dataReloadTokenSelf]);

  const handleCloseDetails = () => {
    setIsOpenModuleDetail(false)
  }
  const handleOpenDetails = (id) => {
    setIsOpenModuleDetail(true);
    setModuleID(id)
  }
  const actualReloadData = () => {
    if (props.reloadData) {
      props.reloadData();
    }
    reloadModuleData();
  };
  return (
    <>
      {!isOpenModuleDetail ? 
        <>
          <div className="module-list-view-mobile module-view">
            <div className="module-list-view-mobile__top">
              <div className="module-list-view-mobile__top__title">
                <h3>Modules</h3>
              </div>
              <div className="module-list-view-mobile__top__button">
                <button type="button" className={`${isOpenDesc ? 'active' : ''}`} onClick={() => setIsOpenDesc(!isOpenDesc)}>
                  About modules
                  <ArrowDown />
                </button>
              </div>
              <div className={`module-list-view-mobile__top__desc ${isOpenDesc ? 'show' : ''}`}>
                <p>
                  Explore in-demand skills with our 6-lesson modules. You’ll progressively build your ability with each lesson to achieve essential competencies in English. Plus, you’ll earn a digital credential that you can share for each completed module.
                </p>
              </div>
            </div>
            { isLoadingBooked ? (
              <div className="module-list-view-mobile__section">
                <div className="--title">
                  <Skeleton animation="wave" variant="text" className="skeleton-heading" />
                </div>
                <div className="--list-section">
                  <ModuleListLoading />
                </div>
              </div>
            ) : (
              <div className="module-list-view-mobile__section">
                <h3 className="block-title">Your booked modules ({totalBooked ?? 0})</h3>
                {moduleListBooked?.length === 0 ? (
                  <p className="message-not-found body-text">You do not have any modules booked.</p>
                ) : (
                  ""
                )}
                {moduleListBooked?.length > 0 && (
                  <div className="--list-section">
                    {moduleListBooked.map((item, index) => (
                      <ModuleViewItemMobile
                        key={`${index}${item.id}`}
                        handleOpenDetails={handleOpenDetails}
                        item={item}
                      />
                    ))}
                    {isLoadingMoreBooked && <ModuleListLoading></ModuleListLoading>}
                  </div>
                )}
                {showLoadMoreButtonBooked && (
                  <div className="btn-show-more-container">
                    <>
                      {!isLoadingMoreBooked ? (
                        <Button variant="contained" onClick={() => loadMore(BOOKED)} className="btn btn-show-more">
                          Load more
                        </Button>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                )}
              </div>
            )}
            
            <div className="module-list-view-mobile__section">
              {isLoadingAvailable ? 
                <div className="--title">
                  <Skeleton animation="wave" variant="text" className="skeleton-heading" />
                </div> : 
                <h3 className="block-title">Available ({totalAvailable ?? 0})</h3>
              }
              <div className="module-list-view-mobile__filter">
                Centre
                <select name="" id="" value={centre} disabled={isLoadingMoreAvailable || isLoadingAvailable || isLoadCentre} onChange={onChangeOption}>
                  {dataOptionAddress && dataOptionAddress.map(item => (
                    <option value={item.id} key={item.id} selected={item.id === centre}>{item.name}</option>
                  ))}
                </select>
              </div> 
              {isLoadingAvailable ? 
                <div className="--list-section">
                  <ModuleListLoading />
                </div>
              : ''}
              {!isLoadingAvailable ? 
                <>
                  {moduleListAvailable?.length === 0 ? (
                    <p className="message-not-found body-text">
                      There are no modules available at the moment.
                    </p>
                  ) : (
                    ""
                  )}
                  {moduleListAvailable?.length > 0 && (
                    <div className="--list-section">
                      {moduleListAvailable.map((item, index) => (
                        <ModuleViewItemMobile
                          key={`${index}${item.id}`}
                          handleOpenDetails={handleOpenDetails}
                          item={item}
                        />
                      ))}
                      {isLoadingMoreAvailable && <ModuleListLoading></ModuleListLoading>}
                    </div>
                  )}
                  {showLoadMoreButtonAvailable && (
                    <div className="btn-show-more-container">
                      <>
                        {!isLoadingMoreAvailable ? (
                          <Button variant="contained" onClick={() => loadMore(AVAILABLE)} className="btn btn-show-more">
                            Load more
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  )}
                </> : 
              ''}
            </div>
          </div>
        </>
      : <ModuleBookingMobile reloadData={actualReloadData} id={moduleID} handleCloseDetails={handleCloseDetails} />}
    </>
  );
};

export default memo(ModuleListViewMobile);
