import { useDispatch, useSelector } from 'react-redux';
import '../../../../sass/private/FbQuestion/FbQuestion.scss';
import { resetFeedback } from '../../../redux/actions/Feedback/FeedbackAction';

const Complete = (props) => {
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(resetFeedback());
        props.onClose();
    };
    const {message} = useSelector(state => state.feedback);

    return (
        <div className="feedback-wrapper">
            <div className="feedback">
                <header>
                <div className="line"></div>
                <h1>Help us improve MyClass</h1>
                </header>
                <main className="main">
                <div className="main-content">
                    <div className="main-desr">
                        <div className="thankyou">{message || "Thank you for your feedback"}</div>
                    </div>
                    <button className="btn-close" onClick={onClose}>Close</button>
                </div>
                </main>
            </div>
        </div>
    )
}

export default Complete;
