import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { windowOpen } from '../../../components/Utilities';
import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../Common/AvailabilityDisplay';

import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';

import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Check from '../../../components/icon/Check';
import Key from '../../../components/icon/Key';
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';



const ItemClass = (props) => {
	const item = props.item || {};

  const history = useHistory();
  const dispatch = useDispatch();

  const onClickLesson = (lessonTimeId) => {
    history.push(`/booking/${lessonTimeId}`);
    dispatch(setOpenBookingWindow(true));
  };

  const isPast = item.isPast;
  const isLocked = item.isLocked;
  let className = "list-item lesson-theme";

  if (isPast) {
    className += "-past";
  }
  else if (isLocked) {
    className += "-locked";
  }
  else if (item.booked) {
    className += "-booked";
  }

  const showJoinNow = item.actionArea?.showButtonJoinClass;
  const joinLink = item.actionArea?.linkJoinClass;
  const joinNowText = item.actionArea?.textJoinClassForLessonBlock;
  const showIconUsers = item.iconSet?.friend?.showIcon;
  const showIconStar = item.iconSet?.wishList?.showIcon;
  const showIconLevel = item.otherLevel;
  const showIconWorkplace = item.iconSet?.workPlace?.showIcon;
  const showIconTick = item.ticked;

  const availabilityText = item.infoLines?.availability;
  const showAlertIcon = item.infoLines?.fullWarningIcon;
  const module = item.module;
  const theme = item.theme_object;

	const cateColor = theme?.product_category_color === '#23085A' ? '#fff' : '#23085A';
  const typeColor = theme?.product_type_color === '#23085A' ? '#fff' : '#23085A';
	return(
		<div 
			className={`${className} ${props.classes ? props.classes : ''}`}
			style={isPast? {}: {borderLeftColor: theme?.pathway_color}}
			onClick={isPast? () => {} : () => onClickLesson(item.lessonTimeId)}
		>
		<div className="flex-container">
			<p className="list-item__title">
				<span className='cate' style={{backgroundColor: theme?.product_category_color ? theme.product_category_color : '#94D070', color: cateColor}}>{theme?.product_category_name}</span>
				<span className='cate' style={{backgroundColor: theme?.product_type_color ? theme.product_type_color : '#94D070', color: typeColor}}>{theme?.product_type_name}</span>
			</p>
			<p className="list-item__name secondary">{ReactHtmlParser(item.title)}</p>
			<div>
				<LocationTeacherDisplay
					time={item.infoLines?.title}
					teacher={item.infoLines?.centreTeacherName?.teacher}
					location={item.infoLines?.centreTeacherName?.centre}
				/>
			</div>
			<div className="list-item__footer">
				<div className="item-footer__left">
					<AvailabilityDisplay availabilityText={availabilityText} showAlertIcon={showAlertIcon} />
				</div>
				<div className="item-footer__right icon-block secondary">
					{showIconUsers && <Users />}
					{showIconStar && <Star />}
					{showIconLevel && <Level code={showIconLevel} />}
					{showIconWorkplace && <Workplace />}
					{showIconTick && <Check />}
					{module && <Layers />}
				</div>
			</div>
			{isLocked &&
				<div className="lesson-locked-display secondary">
					<span className="text">Session locked for students.</span>
					<Key />
				</div>
			}
		</div>
		{showJoinNow && <Button variant="contained" className="btn btn-join-mobile secondary" onClick={(e) => windowOpen(joinLink, e)}>{joinNowText}</Button>}
		</div>
	)
}

export default ItemClass;