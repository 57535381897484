import { useEffect, useState } from "react";
import homeApi from "../../redux/services/homeApi";
import useCheckMobileTablet from "../../../utils/useCheckMobileTablet";
import { useDispatch, useSelector } from "react-redux";
import { moduleTotalAction } from "../../redux/actions/HomePage/HomePageAction";
import calendarApi from "../../redux/services/calendarApi";

const useModule = (reloadData) => {
  const BOOKED = "booked";

  const AVAILABLE = "available";

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingBooking, setLoadingBooking] = useState(true);
  const [isLoadingValidateModule, setIsLoadingValidateModule] = useState(false);
  const [isLoadingAvailable, setIsLoadingAvailable] = useState(true);
  const [isLoadingMoreAvailable, setIsLoadingMoreAvailable] = useState(false);
  const [isLoadingBooked, setIsLoadingBooked] = useState(true);
  const [isLoadingMoreBooked, setIsLoadingMoreBooked] = useState(false);
  const [pageBooked, setPageBooked] = useState(1);
  const [pageAvailable, setPageAvailable] = useState(1);
  const [moduleListBooked, setModuleListBooked] = useState([]);
  const [moduleListLesson, setModuleListLesson] = useState([]);
  const [moduleListAvailable, setModuleListAvailable] = useState([]);
  const [totalBooked, setTotalBooked] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [showLoadMoreButtonBooked, setShowLoadMoreButtonBooked] =
    useState(false);
  const [showLoadMoreButtonAvailable, setShowLoadMoreButtonAvailable] =
    useState(false);

  const [moduleDetail, setModuleDetail] = useState(null);
  const [totalCredit, setTotalCredit] = useState(0);
  const [moduleId, setModuleId] = useState(null);
  const [validateModule, setValidateModule] = useState(null);
  const [validateCancelModule, setValidateCancelModule] = useState(null);
  const [bookedDetail, setBookedDetail] = useState(null);

  const [isShowBooked, setIsShowBooked] = useState(false);
  const [isShowCancel, setIsShowCancel] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [cancelError, setCancelError] = useState();
  const [dataReloadTokenSelf, setDataReloadTokenSelf] = useState(0);
  const { isTablet } = useCheckMobileTablet();
  const [dataDetailReloadTokenSelf, setDataDetailReloadTokenSelf] = useState(0);
  const [centreList, setCentreList] = useState();
  const [isLoadCentre, setIsLoadCentre] = useState(false);
  const [centre, setCentre] = useState(0);

  const getData = async () => {
    setIsLoadingBooked(true);
    setIsLoadingAvailable(true);
    await homeApi
      .getModule(1)
      .then((result) => {
        const paging = result?.data?.paging;
        setPageBooked(1);
        setModuleListBooked(result?.data?.data ?? []);
        setTotalBooked(result?.data?.paging?.total);
        setShowLoadMoreButtonBooked(paging?.current_page < paging?.total_pages);
        setIsLoadingBooked(false);
        setDataReloadTokenSelf(0);
      })
      .catch((e) => {
        setIsLoadingBooked(false);
        setDataReloadTokenSelf(0);
      });
  };
  const getDataAvailable = async (centreID) => {
    setIsLoadingAvailable(true);
    await homeApi
      .getModule(0, centreID)
      .then((result) => {
        const paging = result?.data?.paging;
        setPageAvailable(1);
        setModuleListAvailable(result?.data?.data ?? []);
        setTotalAvailable(result?.data?.paging?.total);
        setShowLoadMoreButtonAvailable(paging?.current_page < paging?.total_pages);
        setIsLoadingAvailable(false);
        setDataReloadTokenSelf(0);
      })
      .catch(() => {
        setIsLoadingAvailable(false);
        setDataReloadTokenSelf(0);
      });
  };
  
  const reloadModuleData = () => {
    setDataReloadTokenSelf(new Date().getTime());
  };
  const reloadModuleDetailData = () => {
    setDataDetailReloadTokenSelf(new Date().getTime());
  };
  const loadMore = async (type) => {
    let page = "";
    switch (type) {
      case BOOKED:
        page = pageBooked + 1;
        setIsLoadingMoreBooked(true);
        setPageBooked(page);
        break;
      case AVAILABLE:
        page = pageAvailable + 1;
        setIsLoadingMoreAvailable(true);
        setPageAvailable(page);
        break;
      default:
        page = pageAvailable + 1;
        setIsLoadingMoreAvailable(true);
        setPageAvailable(page);
    }

    await homeApi
      .getMoreModule(type, page, centre)
      .then((result) => {
        const paging = result?.data?.paging;
        switch (type) {
          case BOOKED:
            const dataBooked = [...moduleListBooked, ...result?.data?.data]
            setModuleListBooked(dataBooked);
            setIsLoadingMoreBooked(false);
            setShowLoadMoreButtonBooked(paging?.current_page < paging?.total_pages);
            break;
          case AVAILABLE:
            const dataAvalable = [...moduleListAvailable, ...result?.data?.data]
            setModuleListAvailable(dataAvalable);
            setIsLoadingMoreAvailable(false);
            setShowLoadMoreButtonAvailable(paging?.current_page < paging?.total_pages);
            break;
          default:
            const data = [...moduleListBooked, ...result?.data?.data]
            setModuleListBooked(data);
            setShowLoadMoreButtonBooked(paging?.current_page < paging?.total_pages);
            setIsLoadingMoreAvailable(false);
        }
        
      })
      .catch(() => {
        switch (type) {
          case BOOKED:
            setIsLoadingMoreBooked(false);
            break;
          case AVAILABLE:
            setIsLoadingMoreAvailable(false);
            break;
          default:
            setIsLoadingMoreBooked(false);
            setIsLoadingMoreAvailable(false);
        }
      });
  };

  const getModuleDetail = async (id) => {
    setIsLoading(true);
    setIsLoadingValidateModule(true);
    setDataDetailReloadTokenSelf(0);
    await homeApi
      .getModuleDetail(id)
      .then((result) => {
        setModuleDetail(result.data ?? null);
        setTotalCredit(result?.data?.total_credit);
        setModuleListLesson(result?.data?.lesson_times ?? [])
        setIsLoading(false);
        
        if (result.data?.available_space === 0 || result.data?.is_booked) {
          setIsLoadingValidateModule(false);
        } else {
          // validate module
          homeApi
          .getValidateModule(id)
          .then((result) => {
            setValidateModule(result?.data);
            setIsLoadingValidateModule(false);
          })
          .catch((e) => {
            setIsLoadingValidateModule(false);
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setIsLoadingValidateModule(false);
      });
    
  };
  const validationBooking = (id, optional) => {
    setIsLoadingValidateModule(true);
    homeApi
    .getValidateModule(id, optional)
    .then((result) => {
      setValidateModule(result?.data);
      setIsLoadingValidateModule(false);
    })
    .catch((e) => {
      setIsLoadingValidateModule(false);
    });
  }
  const handleBookModule = async(id) => {
    if (!isTablet) {
      setIsLoading(true);
    } else {
      setLoadingBooking(true);
    }
    setIsShowCancel(false);
    setIsShowBooked(false);
    setIsShowConfirm(false);
    
    const optional = moduleListLesson.filter(item => {
      return item.bookAgain;
    }).map(item => item.id)
    await homeApi
      .getValidateModule(id, optional)
      .then((result) => {
        setValidateModule(result?.data);
        if (result?.data?.warnings && result?.data?.is_show_messages) {
          setIsShowConfirm(true);
          setIsLoading(false);
          setLoadingBooking(false);
        } else {
          homeApi
            .getBookingModule(id, optional)
            .then((result) => {
              if (result?.success) {
                setBookedDetail(result?.data)
                
                if (reloadData) {
                  reloadData();
                }
                if (isTablet) {
                  reloadModuleDetailData();
                }
              } else {
                setBookedDetail({message: result?.message})
              }
              setIsShowBooked(true);
              setIsLoading(false);
              setLoadingBooking(false);
            })
            .catch((e) => {
              setIsLoading(false);
              setLoadingBooking(false);
            });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setLoadingBooking(false);
      });
  }
  
  const handleContinueBookModule = async(id) => {
    if (!isTablet) {
      setIsLoading(true);
    } else {
      setLoadingBooking(true);
    }
    setIsShowConfirm(false);

    const optional = moduleListLesson.filter(item => {
      return item.bookAgain;
    }).map(item => item.id)
    await homeApi
      .getBookingModule(id, optional)
      .then((result) => {
        if (result?.success) {
          setBookedDetail(result?.data)
        } else {
          setBookedDetail({message: result?.message})
        }
        
        setIsShowBooked(true);
        setIsLoading(false);
        setLoadingBooking(false);

        if (reloadData) {
          reloadData();
        }
        if (isTablet) {
          reloadModuleDetailData();
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setLoadingBooking(false);
      });
  }
  const toggleBookAgain = (id, status, credit, moduleID) => {
    const newArr = moduleListLesson;
    newArr.forEach((item, index) => {
      if (item.id === id) {
        newArr[index].bookAgain = status
      }
    })
    if (status) {
      setTotalCredit(totalCredit + (credit || 0))
    } else {
      setTotalCredit(totalCredit - (credit || 0))
    }
    setModuleListLesson(newArr);
    const optional = moduleListLesson.filter(item => {
      return item.bookAgain;
    }).map(item => item.id);
    validationBooking(moduleID, optional);
  } 

  // cancel module
  const handleCancelModule = async(id) => {
    if (!isTablet) {
      setIsLoading(true);
    } else {
      setLoadingBooking(true);
    }
    setIsShowBooked(false);
    setIsShowConfirm(false);
    await homeApi
      .getValidateCancelModule(id)
      .then((result) => {
        setValidateCancelModule(result?.data);
        setIsShowCancel(true);
        setIsLoading(false);
        setLoadingBooking(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setLoadingBooking(false);
      });
  }
  
  const handleContinueCancelModule = async(id) => {
    if (!isTablet) {
      setIsLoading(true);
    } else {
      setLoadingBooking(true);
    }
    setIsShowConfirm(false);
    await homeApi
      .getCancelModule(id)
      .then((result) => {
        if (result?.success) {
          setIsCanceled(true);
          
          if (reloadData) {
            reloadData();
          }
          if (isTablet) {
            reloadModuleDetailData();
          }
        } else {
          setCancelError(result?.message)
        }
        setIsLoading(false);
        setLoadingBooking(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setLoadingBooking(false);
      });
  }
  
  const getCentreList = () => {
    setIsLoadCentre(true);
    setIsLoadingAvailable(true);
    calendarApi.getFilters().then((result) => {
      setCentreList(result.data.centreList);
      setIsLoadCentre(false);
      const centreID = result?.data?.centreList[0]?.id || 0;
      setCentre(centreID)
      getDataAvailable(centreID);
    })
    .catch((e) => {
      setIsLoadCentre(false);
      setIsLoadingAvailable(false);
    });
  }
  const onChangeOption = (e) => {
    setCentre(e.target.value)
    getDataAvailable(e.target.value)
  }
  useEffect(() => {
    getData();
    getCentreList();
  }, []);
  useEffect(() => {
    if (dataReloadTokenSelf) {
      getData();
    }
  }, [dataReloadTokenSelf]);
  return {
    isLoading,
    isLoadingBooked,
    isLoadingAvailable,
    isLoadingMoreBooked,
    isLoadingMoreAvailable,
    moduleListBooked,
    moduleListAvailable,
    showLoadMoreButtonBooked,
    showLoadMoreButtonAvailable,
    totalBooked,
    totalAvailable,
    loadMore,
    BOOKED,
    AVAILABLE,
    getData,
    moduleDetail,
    getModuleDetail,
    moduleId,
    setModuleId,
    totalCredit,
    isLoadingValidateModule,

    moduleListLesson,
    toggleBookAgain,

    validateModule,

    handleBookModule,
    handleContinueBookModule,

    isShowBooked,
    isShowCancel,
    isShowConfirm,
    bookedDetail,

    isLoadingBooking,

    handleContinueCancelModule,
    handleCancelModule,
    isCanceled,
    cancelError,
    validateCancelModule,
    dataReloadTokenSelf,
    dataDetailReloadTokenSelf,
    reloadModuleData,

    isLoadCentre,
    onChangeOption,
    dataOptionAddress: centreList,
    centre
  };
};

export default useModule;
