import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';

import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';

import iconSpe from '../../../../assets/image/video.png'
import '../../../../sass/private/Home/ItemClassesMobile.scss'
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';

export const ItemClassesMobile = (props) => {
  const { 
    title, 
    time,
    teacher,
    colorHex, 
    defaultLessonParam,
    onClickClasses,
    actionAudio,
    showIconUsers,
    showIconStar,
    showIconLevel,
    showIconWorkplace,
    module
  } = props
  return (
    <div 
      className="item-classes lesson-theme"
      style={{borderColor: `${colorHex ? colorHex : '#C4C4C4'}`}}
      onClick={() => onClickClasses(defaultLessonParam)}>
    <div className="row-1st">
      <div className="item-classes__title">
        <div className="--header">{ReactHtmlParser(title)}</div>
        <div className="--datetime">
          <LocationTeacherDisplay time={time} {...teacher} />
        </div>
      </div>

      {actionAudio && <div className="item-classes__right">
        <img src={iconSpe} alt="iconSpe" />
      </div>}
    </div>
      <div className="row-2nd icon-block secondary">
        {showIconUsers && <Users />}
        {showIconStar && <Star />}
        {showIconLevel && <Level code={showIconLevel} />}
        {showIconWorkplace && <Workplace />}
        {module && <Layers />}
      </div>
    </div>
  )
}

export default ItemClassesMobile