import { Button } from '@material-ui/core';

import PublicPage from '../../components/PublicPage';

import '../../../sass/public/CookiesSettings.scss';
import Logo from '../../../assets/image/british_council.svg';

export default function CookiesSettings() {
  return (
    <PublicPage className="cookies-settings-page">
      <div className="login-header">
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue" />
        <h5 className="login-header__title">Cookie Settings</h5>
        <p className="login-header__text">We use cookies on this website to improve functionality, provide you with a better browsing experience and to tailor our social media advertising. Please click the settings button below for more information and to manage your cookie preferences.</p>
        <Button variant="contained" type="button" className="btn back-button" onClick={() => window.history.back()}>Previous page</Button>
      </div>
      <div className="form-login-block cookies-setting-block">
        <button id="ot-sdk-btn" className="ot-sdk-show-settings" type="button">Cookie Settings</button>
        <div id="ot-sdk-cookie-policy" />
      </div>
    </PublicPage>
  )
}
