import { Button } from '@material-ui/core';

import PublicPage from '../../components/PublicPage';

import '../../../sass/public/NotFound.scss';
import Logo from '../../../assets/image/british_council.svg';

export default function NotFound() {
    return (
      <PublicPage className="not-found-page">
        <div className="login-header">
          <div className="my-class">
            <img src={Logo} alt="British Council" />
          </div>
          <div className="line-blue" />
          <h5 className="login-header__title">Page not found</h5>
          <p className="login-header__text">The requested page could not be found.</p>
          <Button 
            variant="contained"
            type="button"
            className="btn back-button"
            onClick={() => window.history.length <= 1 ? window.location.href="about:blank" : window.history.back()}>
              Previous page
          </Button>
        </div>
      </PublicPage>
    );
}
