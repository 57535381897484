import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bookingWindowAction, getBookingWarningAction } from '../../redux/actions/BookingWindow/BookingWindowAction';
import { bookingAction, cancelBookingAction, setOpenBookingWindow } from '../../redux/actions/Booking/BookingAction';
import { addFavorite, removeFavorite } from '../../redux/actions/MyCoursePage/CourseDetailsPageActions';
import { getCurrentUser } from '../../../utils/currentUser';
import ReactHtmlParser from 'react-html-parser';

const bookingListInitial = [
  {
    id: 1,
    title: "",
    desc: "",
    subDesc: "",
    options: [],
    avatar: '',
    teacherName: '',
    active: false,
    enabled: false,
  },
  {
    id: 2,
    title: "",
    desc: "",
    subDesc: "",
    options: [],
    avatar: '',
    teacherName: '',
    active: false,
    enabled: false,
  },
  {
    id: 3,
    title: "About the teacher",
    desc: "",
    subDesc: "",
    options: [],
    avatar: '',
    teacherName: '',
    active: false,
    enabled: false,
  },
  {
    id: 4,
    title: "Friends who have booked this class",
    desc: "",
    subDesc: "",
    options: [],
    avatar: '',
    teacherName: '',
    active: false,
    enabled: false,
  }
];

const useBookingPage = (reloadData) => {
  const [ bookingList, setBookingList ] = useState(bookingListInitial);
  const [lessonTimeIdBooking, setLessonTimeIdBooking] = useState(-1);
  const dispatch = useDispatch();
  const history = useHistory();
  const open = useSelector(state => state.booking.isOpen);
  const isBooking = useSelector(state => state.bookingWindow?.data?.booked);
  const isShowIconFriend = useSelector(state => state.bookingWindow?.data?.iconSet?.friend?.showIcon);
  const isShowIconLevel = useSelector(state => state.bookingWindow?.data?.otherLevel);
  const isShowIconWorkplace = useSelector(state => state.bookingWindow?.data?.iconSet?.workPlace?.showIcon);
  const isInWishlist = useSelector(state => state.bookingWindow?.data?.iconSet?.wishList?.showIcon);
  const alertMessage = useSelector(state => state.bookingWindow?.data?.alertMessage);
  const msgError = useSelector(state => state.bookingWindow?.msgError);
  const showJoinNow = useSelector(state => state.bookingWindow?.data?.actionArea?.showButtonJoinClass);
  const joinLink = useSelector(state => state.bookingWindow?.data?.actionArea?.linkJoinClass);
  const joinNowText = useSelector(state => state.bookingWindow?.data?.actionArea?.textJoinClassForLessonBlock);
  const friendCount = useSelector(state => state.bookingWindow?.data?.friendCount);
  const friends = useSelector(state => state.bookingWindow?.data?.friends);
  const bookingIsClosed = useSelector(state => state.bookingWindow?.data?.bookingIsClosed);
  let isShowBtnBook = useSelector(state => state.bookingWindow?.data?.actionArea?.showButtonBook);
  let isShowBtnCancel = useSelector(state => state.bookingWindow?.data?.actionArea?.showButtonCancel);
  let isDisableBtnBook = useSelector(state => state.bookingWindow?.data?.actionArea?.disableButtonBook);
  let isDisableBtnCancel = false;

  const bookingWindow = useSelector(state => state.bookingWindow);
  const bookingWindowData = bookingWindow?.data || {};

  const [isLoading, setIsLoading] = useState(true);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
  if (open) { // hot fix to confirm booking window is opened before calling API
    const pathname = history.location.pathname;
    const patnameSplit = pathname.split('/');
    const lessonTimeId = parseInt(patnameSplit[patnameSplit?.length -1]);

    if (isNaN(lessonTimeId)) { // invalid ID, stop all and close modal
      dispatch(setOpenBookingWindow(false));
      dispatch(cancelBookingAction(false));
      dispatch(bookingAction(false));
    }
    else {
      setLessonTimeIdBooking(lessonTimeId);
    }
  }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (open && lessonTimeIdBooking > -1) {
      getData();
    }
  }, [lessonTimeIdBooking]);

  useEffect(() => {
    const isActivity = bookingWindowData.type === "activity";
    let isFirstEnabledItemActive = false;
    const newData = [...bookingList].map((item, index) => {
      const { id } = item;

      if (id === 1) {
        item.title = isActivity? "About this session": "About this lesson";
        item.desc = isActivity?
          (`<p class="active-title">${bookingWindowData.sessionName}</p>
            <p>${bookingWindowData.sessionDescription}</p>`)
          : bookingWindowData.description;
        item.enabled = isActivity ? (isActivity && (bookingWindowData.sessionName !== "" || bookingWindowData.sessionDescription !== "")) : true;
      }
      else if (id === 2) {
        if (isActivity) {
          item.title = "About " + (ReactHtmlParser(bookingWindowData.title) || "");
          item.desc = bookingWindowData.activityDescription;
        }

        item.enabled = isActivity;
      }
      else if (id === 3) {
        item.desc = bookingWindowData.teacherDesciption;
        item.avatar = bookingWindowData.teacherAvatar;
        item.teacherName = bookingWindowData.teacher;
        item.enabled = true;
      } else if (id === 4) {
        if(friendCount > 0) {
          item.title = "Friends who have booked this class ("+ friendCount +")";
          item.desc = friends.map((item) => {
            return `
              <div class="avatar-block avatar-book">
                <div class="avatar-img">
                  <img src=${item.avatar} alt=${item.name} />
                </div>
                <div class="avatar-name">${item.name}</div>
              </div>
            `;
          }).join("");
          item.enabled = true;
        } else {
          item.enabled = false;
        }
      }

      if (!isFirstEnabledItemActive && item.enabled) {
        isFirstEnabledItemActive = true;
        item.active = true;
      }
      else {
        item.active = false;
      }

      return item;
    });
    setBookingList(newData);
    // eslint-disable-next-line
  }, [bookingWindowData]);

  useEffect(() => {
    if (!isShowConfirm) {
      setConfirmMessage("");
    }
  }, [isShowConfirm]);

  const getData = () => {
    setIsLoading(true);

    dispatch(bookingWindowAction(lessonTimeIdBooking)).then((response) => {
      setIsLoading(false);
    });
  }

  const onRedirectPage = () => {
    if (isBooking) {
      let isShowCancelBooking = true;
      dispatch(cancelBookingAction(isShowCancelBooking));
      dispatch(bookingAction(false));
    } else {
      let isShowBookedPage = true;
      dispatch(bookingAction(isShowBookedPage));
    }
  }

  const proceedBooking = () => {
    setIsLoading(true);

    getBookingWarningAction(bookingWindowData.bookWarningParam || "").then((response) => {
      if (response?.success) {
        if (response.data?.showWarning) {
          setIsShowConfirm(true);
          setConfirmMessage(response.data.warningMessage);
        }
        else {
          onRedirectPage();
        }
      }

      setIsLoading(false);
    });
  };

  const onChangeAccordion = (id) => {
    const newBookList = [...bookingList];
    newBookList.filter(item => {
      if(item.id === id) {
        item.active = !item.active;
        return item;
      }
      item.active = false;
      return item;
    })

    setBookingList(newBookList);
  }

  const toggleWishlist = () => {
    const lessonId = parseInt(bookingWindowData?.defaultLessonParam?.toString().split("?")[0]);

    if (!isNaN(lessonId)) {
      setIsLoading(true);

      dispatch(isInWishlist? removeFavorite(lessonId): addFavorite(lessonId)).then(() => {
        if (reloadData) {
          reloadData();
        }

        getData();
      });
    }
  }

  const user = getCurrentUser();
  const isImpersonating = user.isImpersonating || false;
  if (isImpersonating === true) {
    isDisableBtnBook = true;
    isDisableBtnCancel = true;
  }

  return {
    isBooking,
    isShowBtnBook,
    isDisableBtnBook,
    isShowBtnCancel,
    isShowIconFriend,
    isShowIconLevel,
    isShowIconWorkplace,
    msgError: msgError? msgError: alertMessage,
    onRedirectPage,
    proceedBooking,
    isShowConfirm,
    setIsShowConfirm,
    confirmMessage,
    bookingList,
    onChangeAccordion,
    bookingWindowData,
    isLoading,
    showJoinNow,
    joinLink,
    joinNowText,
    isShowWishlistToggle: bookingWindowData?.type === "lesson" || false, // Only lesson has wishlist
    isInWishlist,
    toggleWishlist,
    isDisableBtnCancel,
    bookingIsClosed
  }
}

export default useBookingPage;