import AddressItem from './AddressItem';
import useContact from '../../../hooks/Contact/useContact';

import '../../../../sass/private/Contact/Contact.scss';

const Contact = (props) => {
  const { data } = useContact();
  const onCLose = () => {
    props.onClose();
  }
  return (
    <div className="contact-wrapper">
      <div className="Contact">
        <header>
          <div className="line"></div>
          <h1>Find a centre</h1>
          <button className="btn-close" onClick={onCLose}>Close</button>
        </header>
        <main className="main">
          <div className="main-content">
            <div className="main-desr">
              You can take classes at any of the centres listed below. Those closest to your home centre are shown first.
              <br />
              If you need help or support to use the MyClass student portal or apps, visit our help section. If you cannot find what you need, contact your home centre using the details below.
            </div>
            <div>
              {data?.map(item => {
                return <AddressItem
                  key={item.id}
                  name={item.name}
                  isVirtual={item.is_virtual}
                  isShowHomeCentre={item.is_home_centre}
                  address={item.address}
                  phone={item.phone}
                  lat={item.latitude}
                  lng={item.longitude}
                  email={item.email}
                />
              })}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Contact;