import { memo } from 'react';

import LocationTeacherDisplay from '../../Common/LocationTeacherDisplay';
import Users from '../../../../components/icon/Users';
import Star from '../../../../components/icon/Star';
import Level from '../../../../components/icon/Level/index';
import Workplace from '../../../../components/icon/Workplace';

import '../../../../../sass/private/CalendarPage/CalendarViewItem.scss';

import videoIcon from '../../../../../assets/image/video.png';
import { useHistory } from 'react-router-dom';
import iconMissing from '../../../../../assets/svg/alert-triangle.svg';
// import arrowIconPurple from "../../../../../assets/image/arrow-purple.png";
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../../components/icon/Layers';
import { Button } from '@material-ui/core';

const PassClassViewItem = (props) => {
  const history = useHistory();
  const { typeLesson, title, colorHex, desc, isMissed,
    defaultLessonParam, teacher,
    friendIcon,
    wishListIcon,
    levelsIcon,
    workPlaceIcon,
    actionDetails,
    actionAudio,
    actionText,
    actionRedIcon,
    module,
    theme
  } = props;
  const onClickClasses = () => {
    history.push(`/mycourse/${defaultLessonParam}&historyGo=-2`)
  }
  const cateColor = theme?.product_category_color === '#23085A' ? '#fff' : '#23085A';
  const typeColor = theme?.product_type_color === '#23085A' ? '#fff' : '#23085A';
  return (
    <div
      className="PassClassViewItem lesson-theme"
      style={{borderLeft: `10px solid ${theme?.pathway_color}`}}
    >
      <div className="view-left">
        {theme && 
          <div className="text">
            <span className="product-category" style={{backgroundColor: theme?.product_category_color, color: cateColor}}>{theme?.product_category_name}</span>
            <span className="product-type" style={{backgroundColor: theme?.product_type_color, color: typeColor}}>{theme?.product_type_name}</span>
          </div>
        }
        {module && (
          <p className="module-title">
            <Layers />
            {module.title}
          </p>
        )}
        <div className="textTitle secondary">{ReactHtmlParser(title)}</div>
        <div className="text">
          <LocationTeacherDisplay {...teacher} time={desc} />
        </div>
      </div>
      <div className="view-center">
        <div className="icon-block secondary">
          {friendIcon && <Users />}
          {wishListIcon && <Star />}
          {levelsIcon && <Level code={levelsIcon} />}
          {workPlaceIcon && <Workplace />}
        </div>
      </div>
        <div className="view-right">
          {actionAudio && <img src={videoIcon} alt="Audio" />}
          {actionDetails && <Button variant={"contained"} className={"btn btn-booked"} onClick={onClickClasses} >Details</Button>}
          {/* <div className="homework" onClick={onClickClasses} tabIndex={0} onKeyPress={onClickClasses}>
            {actionDetails && <img src={arrowIconPurple} alt="More information"/>}
          </div> */}
        </div>
        {isMissed && <div className="view-right">
          {actionRedIcon && <img src={iconMissing} alt="Class missed"/>}
          <div className="class-missed">{actionText}</div>
        </div>}
    </div>
  )
}

export default memo(PassClassViewItem);