import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { getRoute } from "../../../components/Utilities";
import { addSkillAssessment } from "../../../redux/actions/assessment";

const LatestScore = (props) => {
    const { score, isMobile } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const onRedirectPage = (id) => {
        dispatch(addSkillAssessment(id));
        history.push(getRoute("ASSESSMENT"));
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        // centerMode: true,
        variableWidth: true,
        appendDots: dots => (
            <div>
                {/* <div className="latest-scores__chart-note">
                    <div className="latest-scores__chart-note__item">
                        <span className="latest-scores__chart-note__color teal"></span>
                        Rating above your average
                    </div>
                    <div className="latest-scores__chart-note__item">
                        <span className="latest-scores__chart-note__color orange"></span>
                        Rating below your average
                    </div>
                </div> */}
                <ul className="slick-dots"> {dots} </ul>
            </div>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: true,
                },
            },
        ],
    };
    console.log(score);
    return (
        <div className='latest-scores'>
            <p className="block-title">Latest scores</p>
            {!isMobile && (
                <>
                    <div className="row">
                        {score && (
                            <>
                                {score.map((item, index) => {
                                    return (
                                        <div className="col" key={index}>
                                            <div className='latest-scores-item' onClick={() => onRedirectPage(item.skillId)}>
                                                <h4 className="latest-scores-item__title">{item.skillType} assessments</h4>
                                                <div className='latest-scores-item__chart'>
                                                    <ul>
                                                        {item.lessonScore?.map(score => (
                                                            <li key={score.id} className={`${score.score == 0 ? "no-scores" : ""}`}>
                                                                <div className="latest-scores-item__chart__column">
                                                                    <span className={score.score < item.averageScore ? 'orange' : ''} style={{height: `${score.score * 10}%`}}></span>
                                                                </div>
                                                                <span className="latest-scores-item__chart__label">{score.score?score.score : '0'}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <div className="latest-scores-item__chart__foot">
                                                        <span className="latest-scores-item__chart__foot__title">Latest</span>
                                                        <svg className='latest-scores-item__chart__foot__icon' width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 13L7 7L1 1" stroke="#23085A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                        
                    </div>
                    
                    <div className={`latest-scores__chart-note ${score?.length === 0 ? "scores-nodata" : ""}`}>
                        <div className="latest-scores__chart-note__item">
                            <span className="latest-scores__chart-note__color teal"></span>
                            Rating above your average
                        </div>
                        <div className="latest-scores__chart-note__item">
                            <span className="latest-scores__chart-note__color orange"></span>
                            Rating below your average
                        </div>
                        <div className="ttl-nodata">You have no scores yet. </div>
                    </div>
                </>
            )}
            {isMobile && (
                <div className="slider-wrapper">
                    {score && (
                        <>
                        <Slider
                            {...settings}
                        >
                            {score.map((item, index) => {
                                return (
                                    <div className="slide-item" key={index}>
                                        <div className='latest-scores-item' onClick={() => onRedirectPage(item.skillId)}>
                                            <h4 className="latest-scores-item__title">{item.skillType} assessments</h4>
                                            <div className='latest-scores-item__chart'>
                                                <ul>
                                                    {item.lessonScore?.map(score => (
                                                        <li key={score.id} className={`${score.score == 0 ? "no-scores" : ""}`}>
                                                            <div className="latest-scores-item__chart__column">
                                                                <span className={score.score < item.averageScore ? 'orange' : ''} style={{height: `${score.score * 10}%`}}></span>
                                                            </div>
                                                            <span className="latest-scores-item__chart__label">{score.score?score.score : '0'}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="latest-scores-item__chart__foot">
                                                    <span className="latest-scores-item__chart__foot__title">Latest</span>
                                                    <svg className='latest-scores-item__chart__foot__icon' width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 13L7 7L1 1" stroke="#23085A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                        <div className={`latest-scores__chart-note ${score?.length === 0 ? "scores-nodata" : ""}`}>
                            <div className="latest-scores__chart-note__item">
                                <span className="latest-scores__chart-note__color teal"></span>
                                Rating above your average
                            </div>
                            <div className="latest-scores__chart-note__item">
                                <span className="latest-scores__chart-note__color orange"></span>
                                Rating below your average
                            </div>
                            <div className="ttl-nodata">You have no scores yet. </div>
                        </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default LatestScore;