import AdvancedSelects from '../Common/Select';
import ProgressBar from '../Common/ProgressBar';
import '../../../../sass/private/MyCourse/ChooseLesson.scss';
import LessonItem from './LessonItem';

const ChooseLesson = (props) => {
  const { 
    listCentres, 
    centreId,
    progressBar, 
    listLessons, 
    listAssociated, 
    onClickLesson, 
    onChangeOption 
  } = props;

  let top = listLessons?.top || []
  let main = listLessons?.main || []
  let bottom = listLessons?.bottom || []
  top.forEach((item, index) => {
    item.is_top = 1;
    if (index === top.length - 1) 
      item.is_last = 1
  });
  main.forEach((item, index) => {
    if(index === main.length - 1)
      item.is_last = 1;
  });
  bottom.forEach((item, index) => {
    item.is_bot = 1;
    if (index === bottom.length - 1)
      item.is_last = 1;
  });
  
  let newListLessons = [...top, ...main, ...bottom]
 
  const lessonJoinedCount = parseInt(progressBar?.lesson_joined_count) || 0;
  const themeLessonCount = parseInt(progressBar?.theme_lessons_count) || 0;

  return (
    <>
      {
        newListLessons?.length > 0 && 
        <div className="ChooseLesson">
          <div className="lesson-title">
            Choose a lesson
          </div>
          <div className="lesson-block">
            <div className="lesson-list">
              <div className="lesson-header">
                <div className="lesson-header__left">
                  {progressBar?.is_showed === 1 && <div className="lesson-header__label">{progressBar.title ?? ""}</div>}
                  {progressBar?.is_showed === 1 && 
                    <ProgressBar
                    label={themeLessonCount? `${lessonJoinedCount} out of ${themeLessonCount} lesson${themeLessonCount > 1? 's': ''} attended`: ''}
                    total={themeLessonCount}
                    count={lessonJoinedCount}
                    isHideInfo={true}
                    styles={ { width: '40px', background: '#23085A' } }
                  />
                  }
                  
                </div>
                <div className="lesson-header__right">
                  <div className="calendar-text">Centre</div>
                  <AdvancedSelects data={listCentres || []} label="" value={centreId} onChangeOption={onChangeOption} />
                </div>
              </div>
              {newListLessons?.map(item => {
                return <LessonItem
                  key={item.id}
                  text={item.name}
                  info={item.infoLines.title}
                  attendedTime = {item.attended ? item.attended_time : ""}
                  checked={item.attended}
                  lessonIcon={item.lessonIcon}
                  disable={item.attended === 1 ? true : false}
                  isRating={item.attended === 0 && item.has_lti_activities === 1}
                  isWishList={item.iconSet?.wishList?.showIcon ?? false}
                  isLocked= {item.is_locked ?? false}
                  isSpeaker={item.has_lti_activities === 0 && item.has_audio === 1}
                  isActivity={item.has_lti_activities === 1}
                  activitiesData={item.lti_activities}
                  lessonId={item.id}
                  lessonType={item.type}
                  onClickLesson={onClickLesson}
                  center_teacher={item.infoLines.centreTeacherName}
                  places={item.infoLines.concatTeacherName}
                  booked={item.colorBlockBackground === "dark"}
                  isTop={item.is_top}
                  isBot={item.is_bot}
                  isLast={item.is_last}
                />
              })
              }
            </div>
            <div className="lesson-write">
              {
                listAssociated?.length > 0 && <div className="text">Recommended {listAssociated[0].recommended_type || ""} lesson</div>
              }
            
              {listAssociated?.length > 0 && listAssociated[0]['lessons']?.map(item => {
                return <LessonItem
                  key={item.id}
                  text={item.name}
                  info={item.infoLines.title}
                  checked={item.attended}
                  lessonIcon={item.lessonIcon}
                  disable={item.attended === 1 ? true : false}
                  isRating={item.attended === 0 && item.has_lti_activities === 1}
                  isSpeaker={item.has_lti_activities === 0 && item.has_audio === 1}
                  isActivity={item.has_lti_activities === 1}
                  activitiesData={item.lti_activities}
                  lessonId={item.id}
                  lessonType={item.type}
                  onClickLesson={onClickLesson}
                  center_teacher={item.infoLines.centreTeacherName}
                  places={item.infoLines.concatTeacherName}
                  booked={item.colorBlockBackground === "dark"}
                  isTop={item.is_top}
                  isBot={item.is_bot}
                />
              })
              }
            </div>
          </div>
      </div>
      }
    </>
  )
}

export default ChooseLesson;