import React, { useEffect, useState } from 'react';
import PublicPage from '../../components/PublicPage';
import { Button, FormGroup, FormLabel, TextField } from '@material-ui/core';
import { validationSchema } from './validationSchema';
import { getRoute } from '../../components/Utilities';
import { useFormik } from 'formik';
import AccoutActiveFooter from './AccoutActiveFooter';
import Logo from '../../../assets/image/british_council.svg';
import useCheckMobileTablet from '../../../utils/useCheckMobileTablet';
import  useQuery from '../../../utils/useQuery'
import accountActivationApi from '../../redux/services/accountActivationApi';

import '../../../sass/public/AccountActivation.scss';

const AccountActivation = (props) => {
  const { isTabletCustom } = useCheckMobileTablet();
  const { student_id, confirmation_code } = useQuery()
  const [errMsgValidate, setErrMsgValidate] = useState('')
  const [errMsgActivation, setErrMsgActivation] = useState('');
  const [studentData, setStudentData] = useState('');
  const { history } = props;


  useEffect(() => {
    const validateActivation = () => {
      return accountActivationApi.validateActivation(student_id, confirmation_code).then(response => {
        if(response && response.success) {
          formik.setFieldValue("accountId", response.data.student_id)
          formik.setFieldValue("accountName", response.data.name)
          setStudentData(response.data);
        } else {
          setErrMsgValidate(response.message)
        }
      })
    }

    validateActivation()
    //eslint-disable-next-line
  }, [])

  const formik = useFormik({
    initialValues: {
      accountId: '',
      accountName: '',
      accountPassword: '',
      accountRePassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData ={
        student_id: student_id,
        confirmation_code: confirmation_code,
        password: values.accountPassword,
        password_confirmation: values.accountRePassword
      }

      return accountActivationApi.activateAccount(formData).then(response => {
        if(response && response.success) {
          // success
          history.push({pathname: getRoute("ACCOUNTACTIVED"), state: { phone: studentData.centre_phone }});
        } else {
          setErrMsgActivation(response.message)
        }
      })
     
    },
  });

  return (
    <PublicPage className="page-activation public-page">
      <div className="activation-header">
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue"></div>
        <h5 className="activation-header__title">Welcome to MyClass</h5>
        <p className="activation-header__text">Please complete your details and choose a password to activate your account</p>
      
        {!isTabletCustom && <AccoutActiveFooter phone={studentData.centre_phone}/>}
      </div>
     
      <div className="form-activation-block">

        {
          errMsgValidate?.length > 0 ? 
            (<div className="error-block" style={{padding: '20px'}}>
              <div className="error-border"></div>
              <div className="error-password">{errMsgValidate}</div>
            </div>) : 
            (
            <form onSubmit={formik.handleSubmit} className="form">
              <FormGroup noValidate autoComplete="off" className="form-activation">
                <FormLabel htmlFor="account-id" className="form-activation__label" style={{ paddingTop: 0 }}>Your student ID</FormLabel>
                <TextField
                  id={'account-id'}
                  name={'accountId'}
                  required
                  className="form-activation__input"
                  variant="outlined"
                  value={formik.values.accountId}
                  onChange={formik.handleChange}
                  inputProps={{readOnly: true}}
                />
                <FormLabel htmlFor="account-name" className="form-activation__label">Name</FormLabel>
                <TextField
                  id={'account-name'}
                  name={'accountName'}
                  required
                  className="form-activation__input"
                  variant="outlined"
                  value={formik.values.accountName}
                  onChange={formik.handleChange}
                  inputProps={{readOnly: true}}
                />

                <div className="description-password">
                  <div className="des-title1">Choose a password</div>
                  <div className="des-title2">Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number</div>
                </div>

                <FormLabel htmlFor="account-password" className="form-activation__label" style={{ paddingTop: '28.5px' }}>Choose password</FormLabel>

                <TextField
                  id={'account-password'}
                  name={'accountPassword'}
                  type="password"
                  required
                  className="form-activation__input"
                  variant="outlined"
                  value={formik.values.accountPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.accountPassword && Boolean(formik.errors.accountPassword)}
                />
                {(formik.touched.accountPassword && formik.errors.accountPassword) &&
                  <div className="error-block">
                    <div className="error-border"></div>
                    <div className="error-password">{formik.errors.accountPassword}</div>
                  </div>
                }
                <FormLabel htmlFor="account-repassword" className="form-activation__label">Re-enter password</FormLabel>
                <TextField
                  type="password"
                  id={'account-repassword'}
                  name={'accountRePassword'}
                  required
                  className="form-activation__input"
                  variant="outlined"
                  value={formik.values.accountRePassword}
                  onChange={formik.handleChange}
                  error={formik.touched.accountRePassword && Boolean(formik.errors.accountRePassword)}
                />
                {(formik.touched.accountRePassword && formik.errors.accountRePassword) &&
                  <div className="error-block">
                    <div className="error-border"></div>
                    <div className="error-password">{formik.errors.accountRePassword}</div>
                  </div>
                }
                <div>
                  <Button variant="contained" type="submit" className="btn btn-indigo">Activate</Button>
                </div>

                {
                  errMsgActivation?.length > 0 && 
                  <div className="error-block">
                    <div className="error-border"></div>
                    <div className="error-password">{errMsgActivation}</div>
                  </div>
                }
                
              </FormGroup>
            </form>
          )
        }
        
      </div>
      {
        isTabletCustom &&  <AccoutActiveFooter phone={studentData.centre_phone} />
      }
         
    </PublicPage>
  )
}

export default AccountActivation;