import { memo } from 'react';
import Tooltip from '../Common/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Check from '../../../components/icon/Check';
import Key from '../../../components/icon/Key';
import AlertTriangle from '../../../../assets/svg/alert-triangle.svg';
import '../../../../sass/private/CalendarPage/CalendarItem.scss';
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';

const CalendarItem = (props) => {
  const {
    title,
    subTitle,
    timeRange,
    borderColor,
    hasFriend,
    favorite,
    isWpe,
    ticked,
    isBooked,
    isPastEndTime,
    lessonTimeId,
    onRedirectBookingWidow,
    otherLevel,
    isLocked,
    showAlertIcon,
    availabilityText,
    module,
    theme
  } = props;
 
  let themeClass = "calendar-item lesson-theme";

  if (isPastEndTime) {
    themeClass += "-past calendar-item__past";
  }
  else if (isLocked) {
    themeClass += "-locked";
  }
  else if (isBooked) {
    themeClass += "-booked";
  }
  if (showAlertIcon && !isPastEndTime) {
    themeClass += " calendar-item__full";
  }
  return (
    <div
      className={themeClass}
      style={isPastEndTime? {}: { borderColor: `${theme ? theme.product_category_color : borderColor}` }}
      tabIndex={0}
      onClick={() => isPastEndTime? {}: onRedirectBookingWidow(lessonTimeId)}
      onKeyPress={() => isPastEndTime? {}: onRedirectBookingWidow(lessonTimeId)}
    >
      {theme && (
        <div className="title" title={ReactHtmlParser(title)}>
          {/* {ReactHtmlParser(title)} */}
          {theme?.product_category_name}
          {theme?.product_type_name ? ` | ${theme?.product_type_name}` : ''}
        </div>
      )}
      <div className="subtitle secondary" title={ReactHtmlParser(subTitle)}>{ReactHtmlParser(subTitle)}</div>
      <div>{timeRange}</div>
      {availabilityText !== "" &&
        <div className="alert">
          {showAlertIcon && <img src={AlertTriangle} alt="Alert" />}
          <span>{availabilityText}</span>
        </div>
      }
      <div className="icon-block secondary">
        {hasFriend && <Users />}
        {favorite && <Star />}
        {otherLevel && <Level code={otherLevel} />}
        {isWpe && <Workplace />}
        {ticked && <Check />}
        {module && <Layers />}
      </div>
      {isLocked &&
        <div className="lesson-locked-display secondary">
          <Tooltip title="Session locked for students.">
            <Key />
          </Tooltip>
        </div>
      }
    </div>
  )
}

export default memo(CalendarItem);