import { Button } from '@material-ui/core';

import PublicPage from '../../components/PublicPage';

import '../../../sass/public/NotFound.scss';
import Logo from '../../../assets/image/british_council.svg';

export default function Maintenance() {
    return (
      <PublicPage className="not-found-page">
        <div className="login-header">
          <div className="my-class">
            <img src={Logo} alt="British Council" />
          </div>
          <div className="line-blue" />
          <h5 className="login-header__title">Site under maintenance</h5>
          <p className="login-header__text">We are carrying out scheduled maintenance and will be back online shortly. Thank you for your patience!</p>
        </div>
      </PublicPage>
    );
}
