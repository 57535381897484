import { useHistory } from 'react-router-dom';

import '../../../../sass/private/Progress/PathwayDetails.scss';
import CircularProgressBar from '../Common/CircularProgressBar';

import ProgressWrapper from './ProgressWrapper';
import PathWays from './Pathways';

import backIcon from '../../../../assets/svg/arrow-left.svg';
import clock from '../../../../assets/svg/clock.svg';
import useProgressPage from '../../../hooks/ProgressPage/useProgressPage';
import { useEffect } from 'react';
import CustomCircularProgress from '../Common/CircularProgress';

const PathwayDetails = () => {
  const {
    pathwayList,
    productId,
    onChangeOption,
    productList, 
    isLoading
  }= useProgressPage()

  const history = useHistory();
  const onGoBack = () => {
    history.goBack();
  }
  const onClickPathwaysLink = (params) => {
    params.c2Id = params.c2Id ? encodeURI(params.c2Id) : "";
    const urlParams = new URLSearchParams(params);
    if (params.c1Id && params.c2Id && params.c3Id && params.c3Type)
      history.push(`/mycourse?${urlParams}`);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  }, [])

  const contentCommunication = (array) => {
    return (
      <div className="comunication-block">
        <div className="comunication-left">
          <div className="comunication-text" onClick={() => onClickPathwaysLink({
            c1Id: array.c1Id || null,
            c2Id: array.c2Id || null,
            c2UniqueId: array.id || null,
            c3Id: array.id || null,
            c3Type: array.c3Type || null
          })}>
            {array.name}
          </div>
        </div>
        <div className="comunication-right">
          <div className="result-block">
              <CircularProgressBar
                strokeWidth="3"
                sqSize="70"
                completeProgress={array.lessonsCount.numOfTotal*10}
                percentage={100- (array.lessonsCount.numOfAttended*10)}
                text={array.lessonsCount.numOfAttended}
              />
            <div className="result-text">Lessons attended</div>
          </div>
          <div className="result-block">
              <CircularProgressBar
                strokeWidth="3"
                sqSize="70"
                completeProgress={array.themeProgressCheck.score*10}
                percentage={100 - (array.themeProgressCheck.score*10)}
                text={array.themeProgressCheck.score*10 + '%'}
              />
            <div className="result-text">Theme progress check score</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ProgressWrapper className="PathwayDetails" isLoading={isLoading} onChangeOption={onChangeOption} productList={productList} productId={productId} hideProductTabs={true}>
      <div className="pathways-header">
        <img src={backIcon} alt="Back" onClick={onGoBack} />
        <span onClick={onGoBack}>Back</span>
        <div className="title">Pathways</div>
      </div>
      {isLoading ? <CustomCircularProgress /> :
      <>
        {
          pathwayList?.length > 0 ?
          pathwayList.map((pathwayItem, index)=> (
            <div key={index}>
              <div className="pathways-content">
                <div className="line-collumn-left"></div>
                <PathWays
                  title={pathwayItem.title}
                  total={pathwayItem.totalTheme}
                  completed={pathwayItem.completeTheme}
                  inProgress={pathwayItem.inProgress}
                />
                {
                  pathwayItem.themeItems.started.map((startedItem, index) => (
                    <div key={index}>
                      <div className="line-row"></div>
                      {contentCommunication(startedItem)}
                      <div className="line-row"></div>
                    </div>
                  ))
                }
              </div>
              <div className="comunication-info">
              {
                pathwayItem.themeItems.notStarted.length > 0 &&
                <>
                  <div>Not started<img src={clock} alt="Not started"/></div>
                  {pathwayItem.themeItems.notStarted.map((notStartedItem, index) => (
                    <p key={index}>{notStartedItem.name}</p>
                  ))}
                </>
              }
              </div>
            </div>
          )) : <p className='pathways-no-results'>No results found</p>
        }
      </>}
    </ProgressWrapper>
  )
}

export default PathwayDetails;