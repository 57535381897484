import { memo } from "react";
import "../../../../sass/private/ProgressBar/ProgressBar.scss";

const ProgressBar = (props) => {
  const {
    total,
    completed,
    width,
    height,
    inProgress,
    colorActive,
    colorDefault,
    colorProgress,
    showNumber,
    data,
    isMobile
  } = props;
  const showColorItem = (index) => {
    if (index < completed) {
      return colorActive;
    } else if (index < completed + inProgress) {
      return colorProgress;
    } else {
      return colorDefault;
    }
  };
  

  return (
    <div className="ProgressBarPathway">
      <div className="progressbar-list__wrapper">
        <div className={`progressbar-list ${showNumber ? 'show-number' : ''}`}>
          {[...Array(total)].map((item, index) => {
            // console.log('data item', item)
            // console.log('data item', data?.items[index])
            return (
              <div
                key={index}
                className={`
                progressbar-item
                ${index < completed ? "active" : ""}
                ${index === 0 ? "first" : ""}
                ${index === total - 1 ? "last" : ""}
                ${inProgress < completed ? "inprogress" : ""}
              `}
                style={{
                  background: showColorItem(index),
                  width: width,
                  height: height,
                }}
              >
                {showNumber && (
                  <span className="progressbar-item-number">{index + 1}</span>
                )}
        
                {(data?.items[index]?.url && !isMobile) ? <a href={data.items[index].url} target="_blank" rel="noreferrer" className="link-grammar">{data.items[index].url}</a> : ''}
             
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressBar);
