import { memo, Fragment } from 'react';
import { Button } from '@material-ui/core';

import ProfileWraper from '../Profile/ProfileWraper';
import useClassBooked from '../../../hooks/BookingPage/useClassBooked';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import CircularProgress from '../Common/CircularProgress';

import ImageZoom from '../../../../assets/image/Booked/zoom.png';
import ImageGoogle from '../../../../assets/image/Booked/google.png';
import ImageOffice from '../../../../assets/image/Booked/office.png';

import ImageCheck from '../../../../assets/image/check-circle.png';
import speakIcon from '../../../../assets/image/speaking.png';
import writeIcon from '../../../../assets/image/writing.png';
import readIcon from '../../../../assets/svg/reading.svg';
import clockIcon from '../../../../assets/svg/clock.svg';
import monitorIcon from '../../../../assets/svg/monitor.svg';
import mapPinIcon from '../../../../assets/svg/map-pin.svg';

import '../../../../sass/private/Booking/Booked.scss';
import ReactHtmlParser from 'react-html-parser';


const ClassBooked = (props) => {
  const { onRedirectPage, reloadData } = props;
  const { messageError, data, isShowCard, isLoading } = useClassBooked(reloadData);
  const { isTablet } = useCheckMobileTablet();

  return (
    <ProfileWraper 
      onClose={onRedirectPage} 
      title="Confirmation"
      isHideBtnBottom 
      isShowClose
      className="booking-profile">
    {isLoading && <CircularProgress />}
    {!isLoading && <>
      {messageError ?
        <div className="booked">
          <div className="booking-title" style={{ border: 0 }}>
            <div className="error-default">{messageError}</div>
          </div>
        </div> :
        <div className="booked">
          <div className="booking-title" style={{ borderLeft: `6px solid ${data?.colorHex}` }}>
            <p className="booking-text">
              <span>{data?.productType}</span>
              {data?.productType === 'Speaking' && <img src={speakIcon} alt="Speaking"/>}
              {data?.productType === 'Writing' && <img src={writeIcon} alt="Writing"/>}
              {data?.productType === 'Reading' && <img src={readIcon} alt="Reading"/>}
            </p>
            <div className="booked-header__title">
              <h2>
                <span>Your booking is confirmed</span>
                {isTablet && <img src={ImageCheck} alt="Confirmed"/>}
              </h2>
              {!isTablet && <img src={ImageCheck} alt="Confirmed"/>}
            </div>
            <p>{ReactHtmlParser(data?.title)}</p>
            <p className="booking-subtitle">
              <img src={clockIcon} alt="Time" />
              <span>{data?.date}</span>
            </p>
            {data?.locationIcon !== 'online' &&
              <p className="booking-text">
                <img src={mapPinIcon} alt="Location" />
                <span>{data?.location}</span>
              </p>
            }
            {data?.locationIcon === 'online' &&
              <p className="booking-text">
                <img src={monitorIcon} alt="Online class" />
                <span>Online class</span>
              </p>
            }
          </div>
          <div className="line-grey full-width"></div>
          <div className="booked-info">
            {data?.isVirtual && <Fragment>
              <div className="booked-info__zoom">
                <img src={ImageZoom} alt="Zoom" />
                {data?.showJoinLink ? <p>
                  <a style={{color: 'var(--color-indigo)'}} href={data?.joinLink} target="_blank" rel="noreferrer">Join class on Zoom</a>
                </p> : <p style={{ textDecoration: 'none' }}>{data?.joinText}</p>}
              </div>
              <div className="line-grey"></div>
            </Fragment>}
          </div>
          <div className="booked-social">
            <p className="booked-social__text">This lesson will now appear in your schedule.
Select one of the options below to add it to your personal calendar too.</p>
            <div className="booked-social__row">
              <div className="social-box social-left">
                <img src={ImageGoogle} alt="Google Calendar" />
                <p className="social-box__text">Google Calendar <a target="_blank" href={data?.googleCalendar}>Add</a></p>
              </div>
              <div className="social-box social-right">
                <img src={ImageOffice} alt="Office Calendar" />
                <p className="social-box__text">Office Calendar <a target="_blank" href={data?.officeCalendar}>Add</a></p>
              </div>
            </div>
          </div>
          <div className="line-grey"></div>
          {isShowCard && <Fragment>
            <div className="booked-cart">
              <p className="booked-cart__text">
                You might be interested to book these lessons as well
              </p>
              <div className="cart-block">
                <div>
                  <div className="cart-image">
                    <img src={''} alt="Social & Art"/>
                  </div>
                  <p className="cart-text">Social & Art</p>
                </div>
                <div>
                  <div className="cart-image">
                    <img src={''} alt="Discussing Movie"/>
                  </div>
                  <p className="cart-text">Discussing Movie</p>
                </div>
                <div>
                  <div className="cart-image">
                    <img src={''} alt="Cultural trips"/>
                  </div>
                  <p className="cart-text">Cultural trips</p>
                </div>
              </div>
            </div>
            <div className="line-grey"></div>
          </Fragment>}
        </div>
      }
    </>}
    </ProfileWraper>
  )
}

export default memo(ClassBooked);